var siteSearchForm = {
	initAutocomplete: function ($searchField) {
		if (typeof $searchField.data('has-autocomplete') == 'undefined' || $searchField.data('hasAutocomplete') == false) {
			//activate swiftype autocomplete
			$searchField.swiftype({
				engineKey: 'yetysyoKNGa-z34iY88M'
			}).attr('data-has-autocomplete', true);
		}
	},
	init: function ($searchField) {
		siteSearchForm.searchSubmit($searchField);
		/* Will not initialize the autocomplete feature until later date.
		if (typeof $.fn.swiftype != 'function') {
			siteSearchForm.loadScripts($searchField);
		} else {
			siteSearchForm.initAutocomplete($searchField);
		}
		*/
	},
	loadScripts: function ($searchField) {
		loadCSS('/css/global/swiftype/autocomplete.css');
		loadJS('/js/global/jquery/plugins/swiftype/jquery.swiftype.autocomplete.js', function () {
			siteSearchForm.initAutocomplete($searchField);
		});
	},
	searchSubmit: function ($searchField) {
		$searchField.closest('form').on('submit', function (e) {
			e.preventDefault();

			var $searchForm = $(this),
			    searchTerm = $searchField.val(),
				url = $wwwURL + '/search/',
				searchFormLoc = '';

			if (searchTerm == '') {
				// if a search term has not been enter, set it to 'blank' it for reporting
				searchTerm = 'n/a';
			} else {
				// pass the search term after it has been encoded for URI
				url += '#stq=' + fixedEncodeURIComponent(searchTerm);
			}

			if ($searchForm.parent().hasClass('header-site-search')) {
				searchFormLoc = 'Header';
				setTimeout(function () {
					window.location = url;
				}, 400);
			} else {
				searchFormLoc = 'Article Header';
				if (window.location.pathname != '/search/') {
					setTimeout(function () {
						window.location = url;
					}, 400);
				}
			}

			if(searchTerm === 'n/a') {
				document.querySelector('.site-search-page .article-container').classList.add('hidden');
			} else {
				document.querySelector('.site-search-page .article-container').classList.remove('hidden');
			}

			if (typeof window.dataLayer == 'object') {
				window.dataLayer.push({
					'event': 'site-search-form',
					'page-section': searchFormLoc,
					'search-term': searchTerm,
					'form-action': 'submit',
					'form-type': 'User Request'
				});
			}

			/** Code for Adobe Analytics ABG-324: start */
			console.log( "SITE SEARCH SELECTED" );
			if( typeof window.dtm_dataLayer == 'object' )
			{
				window.dtm_dataLayer.push({
					'event': 'site-search-submit',
					'page-section': searchFormLoc,
					'search-term': searchTerm
				});
			}
			/** Code for Adobe Analytics ABG-324: end */
		});
	}
};