class PclMbHeader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const template = document.createElement('template');
        template.innerHTML = `<div id="pcl-mb-header"></div>`;
        this.appendChild(template.content.cloneNode(true)); // Append directly to the element instead of the shadow DOM

        this.init();
    }

    init() {
        this.pclLang = docCookies.getItem("pcl_lang") || 'en';
        
        const headerElement = document.querySelector('#pcl-mb-header');
        if (headerElement) {
            this.linkStylesheet(`${$wwwURL}/css/global/web-components/pcl-mb-header/pcl-mb-header.css`)
                .then(() => {
                    const linkElem = document.createElement("link");
                    linkElem.setAttribute("rel", "stylesheet");
                    linkElem.setAttribute("href", `${$wwwURL}/css/global/web-components/pcl-mb-header/pcl-mb-header.css`);
    
                    document.head.prepend(linkElem);
                })
                .catch(() => console.error("style could not be loaded"))
                .then(() => {
                    this.linkStylesheet(`${$wwwURL}/css/global/princess-normalize.css`)
                        .then(() => {
                            const normalize = document.createElement("link");
                            normalize.setAttribute("rel", "stylesheet");
                            normalize.setAttribute("href", `${$wwwURL}/css/global/princess-normalize.css`);
                            document.head.prepend(normalize);
                        })
                        .then(() => {
                            this.buildTemplate();
                            this.render();
                        });
                });
        }
    }

    render() {
    }

    buildTemplate() {
        document.querySelector('#pcl-mb-header').innerHTML = /*html*/`
            <header class="pcl-mb-header"></header>
        `;
    }

    linkStylesheet(url) {
        return new Promise((resolve, reject) => {
            let link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.onload = () => resolve();
            link.onerror = () => reject();
            link.href = url;

            let headScript = document.querySelector('script');
            headScript.parentNode.insertBefore(link, headScript);
        });
    }
}

if (!window.customElements.get('pcl-mb-header')) {
    customElements.define("pcl-mb-header", PclMbHeader);
}