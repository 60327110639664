class PclGlobalNav extends HTMLElement {
	constructor() {
		super();

		// Global Nav Static Links - Top Section
		this.globalNavStaticData = [
			{ "title": "Plan a Cruise", "url": `${$wwwURL}/cruise-search/cruises/ ` },
			{ "title": "On Board Our Ships", "url": `${$wwwURL}/ships-and-experience/onboard-experience/` },
			{ "title": "Destinations", "url": `${$wwwURL}/learn/cruise-destinations/` },
			{ "title": "Booked Guests", "url": `${$bookURL}/manage-booking/index.page?company=PC` },
			{ "title": "Help", "url": `${$bookURL}/captaincircle/customerCare.page` }
		];

		this.header$;
		this.locale = (window.localeData?.status)?.toLowerCase() || 'in';
		this.countryPhone = window.localeData?.countryPhone || '';
		this.lastFocusable;
		this.cruiseDealsLocalizedContent = `
            data-us-url="/cruise-deals-promotions/"
            data-us-text="Cruise Deals"
            data-uk-url="/cruise-deals-promotions/uk/" 
            data-uk-text="Cruise Deals" 
            data-gb-url="/cruise-deals-promotions/uk/" 
            data-gb-text="Cruise Deals"
            data-au-url="/cruise-deals-promotions/au/" 
            data-au-text="Cruise Deals" 
            data-nz-url="/cruise-deals-promotions/au/" 
            data-nz-text="Cruise Deals"
            data-hk-url="/html/global/cruisedeals/hk/top-deals/" 
            data-hk-text="Cruise Deals"
            data-sg-url="/html/global/cruisedeals/sin/top-deals/" 
            data-sg-text="Cruise Deals"
            data-my-url="/html/global/cruisedeals/sin/top-deals/"
            data-my-text="Cruise Deals" 
            data-id-url="/html/global/cruisedeals/sin/top-deals/"
            data-id-text="Cruise Deals"
            data-bn-url="/html/global/cruisedeals/sin/top-deals/"
            data-bn-text="Cruise Deals"
            data-vn-url="/html/global/cruisedeals/sin/top-deals/"
            data-vn-text="Cruise Deals"
            data-track="header"
            data-track-id="Cruise Deals">`;
	}

	connectedCallback() {
		const template = document.createElement('template');
		template.innerHTML = `<div id="pcl-global-nav"></div>`
		this.attachShadow({ mode: "open" }).appendChild(
			template.content.cloneNode(true)
		);

		this.init();
	}

	init() {
		this.pclLang = docCookies.getItem("pcl_lang") || 'en';
		if (this.shadowRoot.querySelector('#pcl-global-nav')) {
			`${this.linkStylesheet(`${$wwwURL}/css/global/web-components/pcl-global-nav/pcl-global-nav.css`)
				.then(
					() => {
						const linkElem = document.createElement("link");
						linkElem.setAttribute("rel", "stylesheet");
						linkElem.setAttribute("href", `${$wwwURL}/css/global/web-components/pcl-global-nav/pcl-global-nav.css`);

						// Attach the created element to the shadow DOM
						this.shadowRoot.appendChild(linkElem);
					},
					() => console.error("style could not be loaded"),
				).then(() => {
					this.linkStylesheet(`${$wwwURL}/css/global/princess-normalize.css`)
						.then(
							() => {
								const normalize = document.createElement("link");
								normalize.setAttribute("rel", "stylesheet");
								normalize.setAttribute("href", `${$wwwURL}/css/global/princess-normalize.css`);
								this.shadowRoot.prepend(normalize);
							}).then(() => {
								this.render();
							})
				})
				}`
		}
	}

	linkStylesheet(url) {
		return new Promise((resolve, reject) => {
			let link = document.createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.onload = () => resolve();
			link.onerror = () => reject();
			link.href = url;

			let headScript = document.querySelector('script');
			headScript.parentNode.insertBefore(link, headScript);
		});
	}

	/**
	 * Does fetch call to get CMS JSON to build template and attaches it to shadow DOM
	 */
	render() {
		const globalNavData = fetch(`${$wwwURL}/html/global/nav/wc/top-nav.json`);

		globalNavData.then(response => {
			return response.json();
		}).then(cmsJsonResponse => {
			this.buildTemplate(cmsJsonResponse);

			// Init Element & Variables
			this.header$ = this.shadowRoot.querySelector(".globalnav");
			this.navContent$ = this.shadowRoot.querySelector('.globalnav__navcontent');

			this.primaryNavItems = this.shadowRoot.querySelectorAll('.globalnav__navitem:not(.globalnav__navitem--contactno)');
			this.primaryNavLinks = this.shadowRoot.querySelectorAll('.globalnav__navitem a[role="button"]');
			this.closeBtns = this.shadowRoot.querySelectorAll(".globalnav__navclose-btn");

			this.dealsSection$ = this.shadowRoot.querySelector('.globalnav__deals-section');
			this.languageDropdown$ = this.shadowRoot.querySelector('.globalnav__language-dropdown');
			this.languageSelector$ = this.shadowRoot.querySelector('.globalnav__language-selector');
			this.cruiseDealsLink$ = this.shadowRoot.querySelector('.globalnav__cruise-deals');
			this.hideLanguage$ = this.shadowRoot.querySelector('.globalnav__language-hide');
			this.chatLink$ = this.shadowRoot.querySelector('.chat-btn');

			// Init Event Listeners
			this.setupNavigation();

			if(this.shadowRoot && this.getAttribute('min-width')) {
				this.shadowRoot.querySelector('nav').style.minWidth = this.getAttribute('min-width');
			}
		});
	}

	/**
	 * List of attribues to watch changes for global header
	 */
	static get observedAttributes() {
		return ['language','phone','nav-links','find-a-cruise','phone-cta','email'];
	}

	/**
	 * Triggered when attributes are added, modified or removed
	 * @param {string} name - name of the attribute
	 * @param {string} oldValue - old value of the attribute
	 * @param {string} newValue - new value of the attribute
	 */
	attributeChangedCallback(name, oldValue, newValue) {
		//this.render(); // BOOKING-9415 - remove duplicate top-nav.json calls
		if (this.shadowRoot && oldValue && oldValue !== newValue) {
			const observedElements = this.shadowRoot.querySelectorAll(`[data-observed-attr='${name}']`);
			observedElements.forEach((element) => {
				if (newValue === 'true') {
					element.classList.remove('hidden');
				} else {
					element.classList.add('hidden');
				}
			});

			this.lastFocusable = (name === 'language' && newValue === 'true') ? 'language-selector' : 'cruise-deals';
        }
    }

	/**
	 * Method to build global nav item - "Plan a Cruise", "On Board Our Ships", "Destinations", "Booked Guests", "Contact Us"
	 * @param {*} globalNavItem - CMS JSON
	 * @param {*} navIndex - Can be from 0 to 4 (Max five items)
	 * @returns HTML for global nav item
	 */
	createGlobalNavItem(globalNavItem, navIndex) {
		const navItem = this.globalNavStaticData[navIndex];
		return /*html*/`
		  <li class="globalnav__navitem">
			  <a
				  href="${navItem.url}"
				  class="globalnav__navlink no-decoration"
				  data-track="primaryNav"
				  data-track-id="${window.convertStringToKebabCase(navItem.title)}"
				  data-page-section="top-nav"
				  role="button"
				  aria-haspopup="true"
				  aria-expanded="false"
				  tabindex="0"
				  >${navItem.title}</a
			  >
			  <div class="globalnav__submenu" id="menu${navIndex}" aria-hidden="true">
				  <div class="globalnav__submenu-container container">
					  <a href="#" 
					  	class="globalnav__navclose-btn globalnav__navclose-btn--subhead no-decoration" 
					  	tabindex="-1" 
					  	role="button"
				  		aria-haspopup="true"
				  		aria-expanded="true"
					  	aria-label="Back to main menu">
						  <span>${navItem.title}</span>
					  </a>
					  <div class="globalnav__subnav-items">
						  ${globalNavItem.categories.map((subNavItem) => this.createSubNavItem(subNavItem)).join('')}
					  </div>
					  <button
						  class="globalnav__navclose-btn globalnav__navclose-btn--x"
						  aria-label="Close ${navItem.title} Menu"
						  tabindex="-1">
						  <svg viewBox="0 0 24 24" id="icon-open-close">
							  <title>open / close icon</title>
							  <path
								  fill-rule="evenodd"
								  d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm6.2,16.12a.75.75,0,1,1-1.06,1.06L12,13.06,6.88,18.19a.75.75,0,0,1-1.06-1.06L10.95,12,5.82,6.87A.75.75,0,0,1,6.88,5.81L12,10.94l5.13-5.13a.75.75,0,1,1,1.06,1.06L13.07,12Z"></path>
						  </svg>
					  </button>
				  </div>
			  </div>
		  </li>`;
	}

	/**
	 * Method to build subnav item - Each global nav item can have upto 3 subnav items
	 * @param {*} subNavItem - CMS JSON - categories 
	 * @returns HTML for subnav item
	 */
	createSubNavItem(subNavItem) {
		const subNavItemId = (subNavItem.name).replaceAll(' ', '-');
		if (subNavItem.name === 'General Support & Sales') {
			return /*html*/`
		  <div class="globalnav__subnav globalnav__contactus">
			<div class="globalnav__subnav-heading" id="${subNavItemId}">${subNavItem.name}</div>
			<ul aria-label="${subNavItemId}">
				${this.createGeneralSupportHrs(subNavItem.navlinks[0])}
			</ul>
		  </div>
		`;
		} else {
			return /*html*/`
		  <div class="globalnav__subnav" id="${subNavItemId}">
			  <div class="globalnav__subnav-heading">${subNavItem.name}</div>
			  <ul aria-label="${subNavItemId}">
				  ${subNavItem.navlinks.map((navLink) => this.createNavLink(navLink)).join('')}
			  </ul>
		  </div>
		`;
		}
	}

	/**
	 * Method to build General Support & Hours from Static HTML String from CMS JSON
	 * @param {*} navLink - CMS JSON - nav link
	 * @returns HTML for Genereal Support & Hours
	 */
	createGeneralSupportHrs(navLink) {
		const generalSupport = window.convertStringToHTML(navLink.body),
			generalSupportPhone = generalSupport.querySelector('.loc-phone-nu'),
			generalSupportHrs = generalSupport.querySelector('.call-center-hours');

		generalSupportPhone.innerHTML = this.countryPhone;
		generalSupportHrs.innerHTML = generalSupportHrs.getAttribute(`data-${this.locale}-text`);
		generalSupportHrs.classList.remove('hidden');
		return generalSupport.innerHTML;
	}

	/**
	 * Method to build nav link - each hyperlink in the global nav item
	 * @param {*} navLink - CMS JSON - nav link 
	 * @param {*} listCustomClass - string
	 * @param {*} anchorCustomClass - string
	 * @returns HTML for nav link list item
	 */
	createNavLink(navLink, listCustomClass, anchorCustomClass,attribute) {
		const attributes = [`data-${this.locale}-url`, `data-${this.locale}-text`];
		const attributesData = window.getAttributesFromLink(navLink?.localizedLink, attributes);
		if(attribute === 'phone'){
			var phoneCTAContext = this.getAttribute('phone-cta');
		}
		return /*html*/`
			<li ${listCustomClass ? `class="${listCustomClass}"` : ''} ${attribute?`data-observed-attr=${attribute}`:''}>
        	    ${attributesData[0] ? `
					<a 
					  class="${anchorCustomClass ? anchorCustomClass : 'invert-decoration'}" 
					  data-track-id="${window.convertStringToKebabCase(navLink.title)}"
					  ${navLink?.localizedLink || ''}
					  href="${attributesData[0] || navLink.url}"
					  data-track="primaryNav"
					  data-page-section="top-nav"
					  tabindex="${listCustomClass ? '0' : '-1'}">
					  ${attributesData[1] || navLink.title}
					</a>`
					: `
					<a 
						class="${anchorCustomClass ? anchorCustomClass : 'invert-decoration'} ${navLink?.styleClass}" 
						data-track-id="${window.convertStringToKebabCase(navLink.title)}"
						${navLink?.localizedLink || ''}
						href="${navLink.url}"
						data-track="primaryNav"
						data-page-section="top-nav"
						tabindex="${listCustomClass ? '0' : '-1'}">
						${phoneCTAContext?`${phoneCTAContext}${navLink.title}` : navLink.title}
					</a>`
				}
			</li>
        `;
	}

	/**
	 * showRecentlyViewedCruisesLink searches localstorage keys using regex.
	 * @returns true if user has "rvc" item in localStorage. If "rvc" item does not exist, returns false.
	 */
	showRecentlyViewedCruisesLink() {
		return Object.keys(localStorage).find(item => item.match(/pcl-ube-search-(.*)-rvc-(.*)/gi)) ? true : false;
	}


	/**
	 * Gets CMS JSON and builds HTML template
	 * @param {*} cmsData 
	 * @returns HTML Template element <template>
	 */
	buildTemplate(cmsData) {
		const attributes = [`data-${this.locale}-url`, `data-${this.locale}-text`];
		const attributesData = window.getAttributesFromLink(this.cruiseDealsLocalizedContent, attributes);
		const cruiseDealslocalizedLinkUrl = attributesData[0];
		const cruiseDealslocalizedLinkTitle = attributesData[1];
		const phoneAttribute = this.getAttribute('phone');
		const emailAttribute = this.getAttribute('email');
		const phoneCTAContextAttribute = this.getAttribute('phone-cta');
		const hideMobileNavLinks = this.getAttribute('nav-links');
		const hideFindACruise = this.getAttribute('find-a-cruise');
		this.shadowRoot.querySelector('#pcl-global-nav').innerHTML = /*html*/`
			<nav class="globalnav">
			  <div class="globalnav__wrapper container">
				  <div class="globalnav__navcontent globalnav--mobile-op0">
					  <ul>
						  ${hideMobileNavLinks===null?cmsData.map((globalNavItem, index) => this.createGlobalNavItem(globalNavItem, index)).join(''):''}
						  ${phoneAttribute && phoneAttribute != 'undefined' ? this.createNavLink({ "title": phoneAttribute, "url": `tel:${phoneAttribute}` }, "globalnav__navitem globalnav__navitem--contactno", "no-decoration","phone") : ''}
						  ${emailAttribute && emailAttribute != 'undefined' ? this.createNavLink({ "title": emailAttribute, "url": `mailto:${emailAttribute}` }, "globalnav__navitem globalnav__navitem--contactno", "no-decoration","email") : ''}
					  </ul>
					  ${hideFindACruise===null?`
						<div class="globalnav__cruise-section hidden-md-up text-center gray-f1-bg">
						  <a href="${$wwwURL}/find/search.do" class="globalnav__find-cruise-btn button font-size-p5 no-decoration">
							Find a Cruise
						</a>
						</div>
						`:''}
					  <div class="globalnav__deals-section hidden-md-up text-center princess-blue-bg">
						  <a
							  href="${$bookURL}/captaincircle/specialOfferRegistration.page?track=header-mobile&trackId=special-offers"
							  class="invert-decoration col-sm-margin-0-bottom">
							  <svg id="email" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								  <title>email</title>
								  <path
									  d="M12.9,13.8a1.613,1.613,0,0,1-.9.3,1.4,1.4,0,0,1-.9-.3L1,6.8V18.5A1.538,1.538,0,0,0,2.5,20h19A1.538,1.538,0,0,0,23,18.5V6.8Z" />
								  <path d="M12,12,22.7,4.5A1.656,1.656,0,0,0,21.5,4H2.5a1.425,1.425,0,0,0-1.2.6Z" />
							  </svg>
							  Special Offers Sign Up
						  </a>
						  ${cruiseDealslocalizedLinkUrl && cruiseDealslocalizedLinkTitle ?  `
						  <a href="${$wwwURL}${cruiseDealslocalizedLinkUrl}" 
						     class="globalnav__cruise-deals invert-decoration"
						     data-page-section="Global Nav" 
						     data-track="global nav"
						     data-track-id="cruise-deals"
						     data-nav-name="Cruise Deals" 
						     data-us-url="${$wwwURL}${cruiseDealslocalizedLinkUrl}">
							  <svg id="icon-tag" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								  <title>tag</title>
								  <path
									  d="M23,10.814V2.537A1.537,1.537,0,0,0,21.463,1H13.186a1.533,1.533,0,0,0-1.08.445c-.267.276-10.678,10.678-10.678,10.678a1.462,1.462,0,0,0,0,2.066l8.381,8.382a1.463,1.463,0,0,0,2.067,0L22.523,11.923A1.525,1.525,0,0,0,23,10.814M15.248,8.752a2.126,2.126,0,1,1,3.006,0,2.128,2.128,0,0,1-3.006,0" />
							  </svg>
							  ${cruiseDealslocalizedLinkTitle}
						  </a>` : '' }
						  ${phoneAttribute && phoneAttribute!='undefined'? `
						  		<a
						  			href="tel:${phoneAttribute}"
						  			class="invert-decoration col-sm-margin-0-bottom">
						  			<svg id="Phone" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							  			<title>Phone</title>
							  			<g>
								  			<path d="M10.418,8.57c0.049-0.018,0.17-0.043,0.275-0.102c0.432-0.24,0.86-0.488,1.286-0.74c0.365-0.217,0.459-0.561,0.249-0.927c-0.855-1.486-1.711-2.971-2.571-4.453C9.453,1.998,9.099,1.904,8.746,2.101C8.306,2.347,7.871,2.598,7.44,2.857C7.099,3.06,7.003,3.404,7.202,3.75c0.86,1.501,1.727,2.998,2.594,4.497C9.916,8.455,10.099,8.564,10.418,8.57z" clip-rule="evenodd" fill-rule="evenodd"></path><path d="M17.597,21.002c0.045-0.015,0.169-0.037,0.273-0.096c0.438-0.244,0.874-0.495,1.306-0.752c0.342-0.204,0.441-0.547,0.245-0.891c-0.86-1.502-1.727-3-2.596-4.495c-0.191-0.329-0.547-0.425-0.876-0.243c-0.454,0.251-0.902,0.512-1.348,0.778c-0.316,0.191-0.416,0.536-0.233,0.856c0.867,1.516,1.743,3.027,2.618,4.539C17.103,20.9,17.286,20.998,17.597,21.002z" clip-rule="evenodd" fill-rule="evenodd"></path><path d="M6.323,3.9c0.937,1.622,1.854,3.213,2.785,4.824C8.668,9.012,8.32,9.39,8.32,9.797c0,0.593,0.107,1.019,0.218,1.529c0.561,1.685,1.361,3.322,2.734,4.516c0.501,0.436,1.071,0.898,1.759,0.882c0.305-0.008,0.34-0.104,0.686-0.171c0.894,1.55,1.815,3.145,2.746,4.758c-1.003,0.586-1.743,0.941-2.912,0.476c-0.506-0.202-0.988-0.494-1.433-0.812c-1.167-0.84-2.137-1.889-3.012-3.021c-1.66-2.148-2.973-4.49-3.86-7.06C4.811,9.635,4.509,8.347,4.48,7.006c-0.025-1.117,0.316-2.072,1.25-2.7C6.016,4.113,6.005,4.103,6.323,3.9z" clip-rule="evenodd" fill-rule="evenodd"></path>
							  			</g>
						  			</svg>
						  			${phoneCTAContextAttribute?phoneCTAContextAttribute:''}${phoneAttribute}
					  		</a> `: ''}
						  
                          <a href="#" class="invert-decoration globalnav__language-selector ${this.getAttribute('language') === 'false' ? 'hidden' : ''}" data-observed-attr="language">
                            <svg id="globe" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <title>globe</title><path d="M12,1A11,11,0,1,0,23,12,11.013,11.013,0,0,0,12,1Zm9.975,10.5H17.483A16.633,16.633,0,0,0,16.7,6.952a14.393,14.393,0,0,0,2.991-1.338A9.943,9.943,0,0,1,21.975,11.5ZM18.989,4.86A13.791,13.791,0,0,1,16.358,6a12.04,12.04,0,0,0-2.265-3.78A9.99,9.99,0,0,1,18.989,4.86ZM7.517,11.5a15.5,15.5,0,0,1,.726-4.2A17.225,17.225,0,0,0,12,7.688a15.259,15.259,0,0,0,3.735-.466,15.5,15.5,0,0,1,.748,4.278Zm8.966,1a15.5,15.5,0,0,1-.726,4.2A17.225,17.225,0,0,0,12,16.312a15.274,15.274,0,0,0-3.736.466A15.539,15.539,0,0,1,7.517,12.5ZM12,2c.808,0,2.34,1.663,3.39,4.274A14.206,14.206,0,0,1,12,6.688a16.233,16.233,0,0,1-3.419-.346C9.631,3.69,11.184,2,12,2Zm-2.092.224A12.128,12.128,0,0,0,7.61,6.09,10.222,10.222,0,0,1,4.958,4.909,9.989,9.989,0,0,1,9.908,2.224ZM4.274,5.66a10.731,10.731,0,0,0,3,1.381A16.6,16.6,0,0,0,6.517,11.5H2.025A9.947,9.947,0,0,1,4.274,5.66ZM2.025,12.5H6.517A16.626,16.626,0,0,0,7.3,17.048a14.393,14.393,0,0,0-2.991,1.338A9.945,9.945,0,0,1,2.025,12.5Zm2.986,6.64A13.791,13.791,0,0,1,7.642,18a12.022,12.022,0,0,0,2.266,3.78A9.988,9.988,0,0,1,5.011,19.14ZM12,22c-.808,0-2.34-1.663-3.39-4.274A14.206,14.206,0,0,1,12,17.312a16.233,16.233,0,0,1,3.419.346C14.369,20.31,12.815,22,12,22Zm2.093-.224a12.146,12.146,0,0,0,2.3-3.866,10.222,10.222,0,0,1,2.652,1.181A9.99,9.99,0,0,1,14.093,21.776Zm5.633-3.436a10.745,10.745,0,0,0-3-1.381,16.6,16.6,0,0,0,.761-4.459h4.492A9.947,9.947,0,0,1,19.726,18.34Z"></path>
                            </svg>
							  Language
						  </a>
					  </div>
                      <div class="globalnav__language-dropdown hidden hidden-md-up text-center">
                        <a class="globalnav__language-hide invert-decoration" tabindex="0">Hide Languages
                            <svg class="svg-icon" id="icon-down-arrow" aria-hidden="false" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <title>down arrow</title><path d="M19.9697,7.6387c-0.417-0.4189-1.0986-0.4189-1.5176,0L12,14.0889L5.5493,7.6387c-0.418-0.4189-1.1006-0.4189-1.5171,0c-0.4185,0.416-0.4185,1.0977,0,1.5156l7.208,7.2109c0.21,0.207,0.4834,0.3105,0.7598,0.3105c0.2754,0,0.5518-0.1035,0.7598-0.3105l7.21-7.2109C20.3857,8.7363,20.3857,8.0547,19.9697,7.6387"></path>
                            </svg>
                        </a>
						<div class="globalnav__language-dropdown-links">
							<a href="#" class="invert-decoration globalnav__language-link${this.pclLang === 'en' ? ' active' : ''}" lang="en" data-language-select="en|uat" data-track="header" data-track-id="English" tabindex="0" aria-selected="true">English</a>
                        	<a href="#" class="invert-decoration globalnav__language-link${this.pclLang === 'es' ? ' active' : ''}" lang="es-la" data-language-select="es|es-uat" data-track="header" data-track-id="Spanish" tabindex="0">Español</a>
                        	<a href="#" class="invert-decoration globalnav__language-link${this.pclLang === 'jp' ? ' active' : ''}" lang="ja-jp" data-language-select="jp|jp-uat" data-track="header" data-track-id="Japanese" tabindex="0">日本語<span class="visuallyhidden">Japanese</span></a>
						</div>
                      </div>
				  </div>
			  </div>
		  </nav>`;
	}

	/**
	 * Toggle globalnav dropdown
	 * @param {*} primaryNavItem Is the the primary nav list item e.g. Plan a cruise
	 * @param {*} isOpen accepts true or false values
	 */
	toggleDropdown(primaryNavItem, isOpen) {
		const primaryNavLink = primaryNavItem.querySelector('.globalnav__navlink');
		const primaryNavSubmenu = primaryNavItem.querySelector('.globalnav__submenu');
		const interactiveElements = primaryNavSubmenu.querySelectorAll('[tabindex]');
		const viewportDimensions = window.getViewportDimensions();
		primaryNavLink.setAttribute('aria-expanded', isOpen);
		primaryNavSubmenu.setAttribute('aria-hidden', !isOpen);

		if (isOpen) {
			primaryNavItem.classList.add('is-active');
			interactiveElements.forEach((tabbableElement) => tabbableElement.tabIndex = 0);
			if (viewportDimensions.width > 767) {
				let height = primaryNavSubmenu.offsetHeight;
				this.header$.style.marginBottom = height + "px";
			}
		} else {
			primaryNavItem.classList.remove('is-active');
			interactiveElements.forEach((tabbableElement) => tabbableElement.tabIndex = -1);
			if (viewportDimensions.width > 767) {
				let height = primaryNavSubmenu.offsetHeight;
				this.header$.style.marginBottom = height + "px";
			}
		}
	}

	/**
	 * Close all dropdowns
	 */
	closeAllDropdowns() {
		this.primaryNavItems.forEach((primaryNavItem) => {
			if (primaryNavItem.classList.contains('is-active')) {
				this.toggleDropdown(primaryNavItem, false);
			}
		});
	}

	/**
	 * Toggles drawer based on input parameter. Also, dispatches custom event which can be used across the app.
	 * @param {*} show accepts true or false to open or close the drawer
	 */
	toggleDrawer(show) {
		this.navContent$.classList.remove('globalnav--mobile-op0');
		if (show) {
			this.header$.classList.add('globalnav--drawer-expanded');
			window.dispatchEvent(new CustomEvent('pcl-global-nav.draweropened'));
			document.documentElement.style.overflow = 'hidden';
		} else {
			this.header$.classList.remove('globalnav--drawer-expanded');
			window.dispatchEvent(new CustomEvent('pcl-global-nav.drawerclosed'));
			document.documentElement.style.overflow = 'auto';
		}
	}

	/**
	 * 
	 * @param {*} primaryNavLink Is the the primary nav list item e.g. Plan a cruise
	 * @param {*} event Event
	 * @param {*} eventType Accepts two types values, either 'click' or 'keyboard'
	 */
	handlePrimaryNavEvent(primaryNavLink, event, eventType) {
		const primaryNavItem = primaryNavLink.parentNode;
		const isActive = primaryNavItem.classList.contains('is-active');
		const closeSubhead = primaryNavItem.querySelector('.globalnav__navclose-btn--subhead');
		event.preventDefault();
		this.closeAllDropdowns();
		this.toggleDropdown(primaryNavItem, !isActive);
		if (eventType === 'click') primaryNavLink.blur();
		if (eventType === 'keyboard' && !isActive) closeSubhead.focus();
	}

	/**
	 * Initialize navigation and setup event listeners
	 */
	setupNavigation() {
		this.primaryNavLinks.forEach((primaryNavLink) => {
			primaryNavLink.addEventListener('click', (e) => {
				this.handlePrimaryNavEvent(primaryNavLink, e, 'click');
			});

			primaryNavLink.addEventListener('keydown', (e) => {
				if (e.keyCode === 13 || e.keyCode === 32) {
					this.handlePrimaryNavEvent(primaryNavLink, e, 'keyboard');
				}
			});
		});

		this.closeBtns.forEach((closeBtn) => {
			closeBtn.addEventListener("click", (e) => {
				e.preventDefault();
				const primaryNavItem = e.currentTarget.closest('.globalnav__navitem');
				this.toggleDropdown(primaryNavItem, false);
			});

			closeBtn.addEventListener("keydown", (e) => {
				if (e.keyCode === 13 || e.keyCode === 32) {
					e.preventDefault();
					const primaryNavItem = e.currentTarget.closest('.globalnav__navitem');
					this.toggleDropdown(primaryNavItem, false);
					primaryNavItem.querySelector('a').focus();
				}
			});
		});

		/**
		  * When escape button is pressed, close all dropdowns
		*/
		document.addEventListener('keydown', (e) => {
			if (e.keyCode === 27) {
				this.closeAllDropdowns();
			}

			if (e.keyCode === 9 && this.header$.classList.contains('globalnav--drawer-expanded')) {
				const globalHeader = document.querySelector('pcl-global-header');
				const firstFocusableEl = globalHeader.shadowRoot.querySelector('.global-header__menu-btn');
				const lastFocusableEl = this.lastFocusable === 'language-selector' ? this.languageSelector$ : this.cruiseDealsLink$;
				const currentActiveElHeader = globalHeader.shadowRoot.activeElement;
				const currentActiveElGlobalNav = this.shadowRoot.activeElement;
				if (e.shiftKey && currentActiveElHeader === firstFocusableEl) {
					e.preventDefault();
					lastFocusableEl.focus();
				} else if (!e.shiftKey && currentActiveElGlobalNav === lastFocusableEl) {
					e.preventDefault();
					firstFocusableEl.focus();
				}
			}
		});

		/**
		 * custom event to open global nav drawer
		 */
		window.addEventListener('pcl-global-nav.opendrawer', () => {
			this.toggleDrawer(true);
		});

		/**
		 * custom event to close global nav drawer
		 */
		window.addEventListener('pcl-global-nav.closedrawer', () => {
			this.toggleDrawer(false);
			this.closeAllDropdowns();
		});

		/**
		 * Show language options
		 */
		this.languageSelector$.addEventListener('click', (e) => {
			this.dealsSection$.classList.add('hidden');
			this.languageDropdown$.classList.remove('hidden');
		});

		/**
		 * Hide language options
		 */
		this.hideLanguage$.addEventListener('click', (e) => {
			this.languageDropdown$.classList.add('hidden');
			this.dealsSection$.classList.remove('hidden');
		});

		/**
		 * Set pcl_lang cookie with selected language
		 */
        this.languageDropdown$.addEventListener('click', (e) => {
            const selectedLanguage = e.target.getAttribute('data-language-select');
            if (selectedLanguage) {
				const languageCode = selectedLanguage.split('|');
                this.languageDropdown$.querySelectorAll('a').forEach((languageLink) => {
                    languageLink.classList.remove('active');
                });

                this.languageDropdown$.querySelector(`a[data-language-select='${selectedLanguage}']`).classList.add('active');
                docCookies.setItem('pcl_lang', languageCode[0], 31536000, '/', 'princess.com', true);
            }
        });

		window.setupLanguageSelector(this.shadowRoot.querySelector('.globalnav__language-dropdown-links'));

		/* set a listener for chat link to be triggered */

		if(this.chatLink$){
			this.chatLink$.addEventListener('click', (event) => {
				window.dispatchEvent(new Event('pcl-global-events.chat'));
			});
		}
	}
}

if (!window.customElements.get('pcl-global-nav')) {
	customElements.define("pcl-global-nav", PclGlobalNav);
}