class PclCaptainCircleHeader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const template = document.createElement('template');
        template.innerHTML = `<div id="pcl-captain-circle-header"></div>`
        this.attachShadow({ mode: "open" }).appendChild(
            template.content.cloneNode(true)
        );

        this.init();
    }

    init() {
        this.pclLang = 'en';
        if (this.shadowRoot.querySelector('#pcl-captain-circle-header')) {
            `${this.linkStylesheet(`${$bookURL}/css/global/web-components/pcl-captain-circle-header/pcl-captain-circle-header.css`)
                .then(
                    () => {
                        const linkElem = document.createElement("link");
                        linkElem.setAttribute("rel", "stylesheet");
                        linkElem.setAttribute("href", `${$bookURL}/css/global/web-components/pcl-captain-circle-header/pcl-captain-circle-header.css`);

                        // Attach the created element to the shadow DOM
                        this.shadowRoot.prepend(linkElem);
                    },
                    () => console.error("style could not be loaded"),
                ).then(() => {
                    this.linkStylesheet(`${$wwwURL}/css/global/princess-normalize.css`)
                        .then(
                            () => {
                                const normalize = document.createElement("link");
                                normalize.setAttribute("rel", "stylesheet");
                                normalize.setAttribute("href", `${$wwwURL}/css/global/princess-normalize.css`);
                                this.shadowRoot.prepend(normalize);
                            }).then(() => {
                                this.buildTemplate();
                                this.buildMobileTemplate();
                                this.buildManageBookingModalTemplate();
                                this.buildLoginModalTemplate();
                                this.setupEventListeners();
                            })
                })
                }`
        }
    }

    buildTemplate() {
        this.shadowRoot.querySelector('#pcl-captain-circle-header').innerHTML = /*html*/`
            <pcl-system-outage></pcl-system-outage>
                <header class="captain-circle-global-header desktop">
                    <div class="header-container">
                        <div class="header-actions-container">
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Subscribe
                                </a>
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                1-800-774-6237
                            </a>
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Search
                            </a>
                        </div>
                        <div id="header-login-buttons-container" class="header-actions-container">
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Favorites
                            </a>
                            <button id="manage-booking-button" class="header-action">
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Manage Booking
                            </button>
                            <button id="login-button" class="header-action">
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Login
                            </button>
                        </div>
                    </div>
                </header>
            <div class="global-header__nav-container-overlay hidden-md-up"></div>
        `;
    }

    buildMobileTemplate() {
        const manageBookingButton = this.shadowRoot.querySelector('#pcl-captain-circle-header');
        const manageBookingModalTemplate = document.createElement('template');

        manageBookingModalTemplate.innerHTML = `
        <pcl-system-outage></pcl-system-outage>
            <header class="captain-circle-global-header mobile">
                <div class="header-container">
                    <div class="header-actions-container">
                        <div class="header-actions-container-section">
                            <a class="header-action has-line" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Search
                            </a>
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                1-800-774-6237
                            </a>
                        </div>
                        <div class="header-actions-container-section">    
                            <a class="header-action has-line" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Subscribe
                            </a>
                            <a class="header-action" href='#'>
                                <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                                Favorites
                            </a>
                        </div>
                    </div>
                    <div id="header-login-buttons-container" class="header-buttons-container">
                        <button id="manage-booking-button" class="header-button">
                            <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                            Manage Booking
                        </button>
                        <button id="login-button" class="header-button">
                            <svg class="svg-icon headerbar-btn-icon" aria-hidden="true"><use xlink:href="#icon-user" /></svg>
                            Login
                        </button>
                    </div>
                </div>
            </header>
        <div class="global-header__nav-container-overlay hidden-md-up"></div>`;

        manageBookingButton.appendChild(manageBookingModalTemplate.content.cloneNode(true));
    }

    buildManageBookingModalTemplate() {
        const manageBookingButton = this.shadowRoot.querySelector('#manage-booking-button');
        const manageBookingModalTemplate = document.createElement('template');

        manageBookingModalTemplate.innerHTML = `
        <div id="manage-booking-modal" class="hidden">
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
            <div class="manage-booking-modal-item">
                <a class="manage-booking-modal-item-link" href="#">Booking Summary</a>
            </div>
        </div>`;

        manageBookingButton.appendChild(manageBookingModalTemplate.content.cloneNode(true));
    }

    buildLoginModalTemplate() {
        const loginButton = this.shadowRoot.querySelector('#login-button');
        const loginModalTemplate = document.createElement('template');

        loginModalTemplate.innerHTML = `
        <div id="login-modal" class="hidden">
            <p class="login-modal-login-text">
                Already booked? Sign in or create an account.
            </p>
            <button class="header-login-button">
                SIGN IN
            </button>
        </div>`;

        loginButton.appendChild(loginModalTemplate.content.cloneNode(true));
    }

    linkStylesheet(url) {
        return new Promise((resolve, reject) => {
            let link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.onload = () => resolve();
            link.onerror = () => reject();
            link.href = url;

            let headScript = document.querySelector('script');
            headScript.parentNode.insertBefore(link, headScript);
        });
    }

    setupEventListeners() {        
        this.headerLoginButtonsContainer = this.shadowRoot.querySelector('#header-login-buttons-container');
        this.manageBookingButton = this.shadowRoot.querySelector('#manage-booking-button');
        this.manageBookingModal = this.shadowRoot.querySelector('#manage-booking-modal');
        this.loginButton = this.shadowRoot.querySelector('#login-button');
        this.loginModal = this.shadowRoot.querySelector('#login-modal');

        this.manageBookingButton.addEventListener('click', (e) => this.toggleManageBookingModal(e));
        this.loginButton.addEventListener('click', () => this.toggleLoginModal());        
    }



    toggleManageBookingModal(e) {        
        this.loginModal.classList.add("hidden");
        
		if(this.manageBookingModal.classList.contains("hidden")) this.manageBookingModal.classList.remove("hidden");
		else this.manageBookingModal.classList.add("hidden");
    }

    toggleLoginModal() {
        this.manageBookingModal.classList.add("hidden");
        
		if(this.loginModal.classList.contains("hidden")) this.loginModal.classList.remove("hidden");
		else this.loginModal.classList.add("hidden");
    }
}

if (!window.customElements.get('pcl-captain-circle-header')) {
    customElements.define("pcl-captain-circle-header", PclCaptainCircleHeader);
}
