/* Adjusts the padding of the links in the nav to fit smaller screens */
function responsiveNav($nav,$navBtn) {
	var navBtnLength = $navBtn.length,
		navBtnWidth = 0;
		
	//set button padding on initial load based on current viewport size
	if (jQuery(window).width() > 767) {
		navBtnWidth = totalBtnWidth($navBtn);
		//set button padding based on current viewport size
		adjustNavPadding($nav,$navBtn,navBtnWidth,false);
	}
	
	//reset button padding based on resized viewport
	jQuery(window).on('resize',function() {
		if (jQuery(window).width() > 767) {
			if (navBtnWidth == 0) {
				navBtnWidth = totalBtnWidth($navBtn);
			}
			adjustNavPadding($nav,$navBtn,navBtnWidth,false);
		} else {
			adjustNavPadding($nav,$navBtn,navBtnWidth,true);
		}
	});
}

function totalBtnWidth($navBtn) {
	var navBtnWidth = 0;
	$navBtn.each(function () {
		navBtnWidth = navBtnWidth + $(this).width();
	});
	
	return navBtnWidth;
}

function adjustNavPadding($nav,$navBtn,navBtnWidth,resetNavPad) {
	if (resetNavPad) {
		//apply button padding
		$navBtn.each(function() {
			jQuery(this).removeAttr('style');
		});
	} else {
		var navWidth = $nav.width();
		if(navWidth > 990){
			navWidth = 990;
		}
		var btnPadding = Math.floor((((navWidth - navBtnWidth) / $navBtn.length) / 2) - 1);
		
		//apply button padding
		$navBtn.each(function() {
			jQuery(this).css({'padding-left':btnPadding,'padding-right':btnPadding});
		});
	}
}