// UPDATED header_footer_nav.js FOR TOP NAV REDESIGN


/*
Purpose: Creates global header, footer and navigation, Login/Logout functionalities, Show Special Offers Sign-up screen, Show Inactivity Screen.
-------------------------
2) Create Footer- Creates localized footer(using 'footer_localized.js' which is generated by CMS).
3) Hide/Show Sign-in box and load sign-in page in iframe
4) Login/Logout Functionality. Associated methods: guestAuth.status(), notYou(), signOut()
5) Reset Phone/Search Width - when the phone numbers are present, most often the phone-search section will be wider than the page-header-links. Whe this occurs, the script below will resize page-header-links to match the phone-search width
8) Show/Hide Inactivity Screen (based on session/cookie timeout) -Associated methods: show_inactivity_screen(),inactivity_screen_show_hide(),center_inactivity_lightbox(), prepareInactivity(),keepAlive(),setSessionCookie, clearSession(), resetSessionCookie(), deleteCokie()
7) Search Submit- doSubmitSearch()
*/


/*
 * Define Console.log for browsers that does not support
 */
if(typeof(console) === 'undefined') {
    var console = {}
    console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function() {};
}

/*
 * For pages that don't have princess.combined.css or princess-normalize.css / magnific-popup.css, we need to load wide_header_footer_helper.css / possibly magnific-popup.css
 */
var loadHelperCSS = true;
var loadMfpCSS = true;
var loadActionToolbarCSS = true;
for(var i = 0; i < document.styleSheets.length; i++){
    var styleSheetUrl = document.styleSheets[i].href;
    if (styleSheetUrl != undefined && styleSheetUrl != null) {
        if (styleSheetUrl.lastIndexOf('princess.combined.css') > -1 || styleSheetUrl.lastIndexOf('princess-normalize.css') > -1 || styleSheetUrl.lastIndexOf('air.combined.css') > -1 ) {
            loadHelperCSS = false;
        }
        if (styleSheetUrl.lastIndexOf('princess.combined.css') > -1 || styleSheetUrl.lastIndexOf('magnific-popup.css') > -1 || styleSheetUrl.lastIndexOf('air.combined.css') > -1 ) {
            loadMfpCSS = false;
        }
    if (styleSheetUrl.lastIndexOf('princess.combined.css') > -1 || styleSheetUrl.lastIndexOf('action-widget.css') > -1 || styleSheetUrl.lastIndexOf('air.combined.css') > -1 ) {
            loadActionToolbarCSS = false;
        }
    }
}
if (loadHelperCSS == true) {
    loadCSS('/css/global/wide_header_footer_helper.css');
}
if (loadMfpCSS == true) {
    loadCSS('/css/global/magnific-popup/magnific-popup.css');
}
if(loadActionToolbarCSS == true){
  loadCSS('/css/global/action-widget.css');
}

(function ($) {
    $(document).ready(function () {
        /*
         * Temporary fix - add extra classes to princess-header when user is in CP
         */
        var pathname = DOMPurify.sanitize(window.location.pathname),
            origin = DOMPurify.sanitize(window.location.origin);
        if (origin == $bookURL) {
            if ((pathname.indexOf('/manage-booking/') > -1 && pathname.indexOf('/manage-booking/index.page') == -1 && pathname.indexOf('/manage-booking/login.page') == -1 && pathname.indexOf('/manage-booking/logout.page') == -1 && pathname.indexOf('/manage-booking/loginExternal.page') == -1) || (pathname.indexOf('/excursions/') > -1)) {
                $('#princess-header').addClass('show-headerbar-logo hide-headerbar-cruise-deals hide-headerbar-saved-cruises hide-headerbar-site-search hide-headerbar-email-offers hide-headerbar-find-cruises show-headerbar-call');
            }
        }

        /**
         * Global messages from Sign In & Special Offers modals
         */
        window.addEventListener('message', function (event) {
            if (event.origin !== $bookURL && event.origin !== $wwwURL) return;
            if (event.data.resizeModal) {
            	$(".mfp-content").css({'height': event.data.height});
            }
        }, false);

        /*
         * Header Site Search Events
         */
        var $headerbarSiteSearchBtn = $('#headerbar-site-search-btn'),
            $headerSiteSearchForm = $('#header-site-search-field');
        // open header site-search for mobile devices
        $headerbarSiteSearchBtn.on('click', function () {
            $headerbarSiteSearchBtn.attr('aria-expanded','true');
            headerSiteSearch.show(false);
        });
        // close header site-search for mobile devices
        $('#close-headerbar-site-search-btn').on('click', function () {
            $headerbarSiteSearchBtn.attr('aria-expanded','false');
            $headerbarSiteSearchBtn.trigger('focus');
            headerSiteSearch.hide(false);
        });
        // Use the esc key to close headerbar-site-search
        $(document).on('keydown', function (e) {
            if (e.which === 27 && $headerbarSiteSearchBtn.attr('aria-expanded') === 'true') {
                headerSiteSearch.hide(false);
                $headerbarSiteSearchBtn.trigger('focus');
                $headerbarSiteSearchBtn.attr('aria-expanded','false');
            }
        });
        // initialize the site-search-form events
        if ($headerSiteSearchForm.length > 0) {
            if (typeof siteSearchForm === 'function') {
                siteSearchForm.init($headerSiteSearchForm);
            } else {
                loadJS('/js/global/site-search-form.js', function () {
                    siteSearchForm.init($headerSiteSearchForm);
                });
            }
        }

        /*
         * Header & Navbar Events
         */
        var $headerbar = $('#headerbar'),
            hasHeaderbar = ($headerbar.length > 0 ? true : false),
            $headerbarMenuBtn = $('#headerbar-menu-btn'),
            $navbar = $('#navbar'),
            hasNavbar = ($navbar.length > 0 ? true : false),
            $footer = $('#princess-footer'),
            hasFooter = ($footer.length > 0 ? true : false);

        // open & close navbar for mobile devices
        $headerbarMenuBtn.on('click', function (e) {
            if ($headerbarMenuBtn.attr('aria-expanded') == 'false') {
                navbar.expandMobileMenu($headerbarMenuBtn,$navbar);
            } else {
                navbar.hideMobileMenu($headerbarMenuBtn,$navbar);
            }
        });

        // Close the mobile menu when user has it open and then resizes browser larger viewport that does not use the mobile menu */
        // Only setting the variable on resize event so that we don't lock up browser when doing a lot of scrolling
        var hasResized = false;
        $(window).on('resize', function (e) {
            hasResized = true;
        });
        // Using setInterval to check the hasResized variable to determine if the browser has been resized. If so, check to see if we need to close the mobile menu
        setInterval(function () {
            if (hasResized) {
                hasResized = false;
                // make sure #navbar is on the page first
                if (hasNavbar === true) {
                    //if going from mobile to desktop
                    if($headerbarMenuBtn.is(':hidden')){
                        // automatically close the mobile menu if the $headerbarMenuBtn is hidden & expanded state is true
                        if($headerbarMenuBtn.attr('aria-expanded') == 'true'){
                            navbar.hideMobileMenu($headerbarMenuBtn,$navbar);
                        }
                        //disable tabs from inside mobile menu
                        $navbarElements = $navbar.find('.my-account-link, .navbar-btn-menu-link, .navbar-dropdown-toggle, .navbar-search-cruises .button, .navbar-search-cruises .recently-viewed-cruises');
                        $navbarElements.attr('tabindex', '0');
                        $navbar.attr('aria-hidden',false);
                    } else{ //if going from desktop to mobile
                        //renable tabbing function of the mobile menu
                        $navbarElements = $navbar.find('.my-account-link, .navbar-btn-menu-link, .navbar-dropdown-toggle, .navbar-search-cruises .button, .navbar-search-cruises .recently-viewed-cruises');
                        $navbarElements.attr('tabindex', '-1');
                        $navbar.attr('aria-hidden',true);
                    }
                }
            }
        }, 50);

        // Close the dropdown when user scrolls a minimum distance on tablet and desktop
        // Only setting the variable on scroll event so that we don't lock up browser when doing a lot of scrolling
        var hasScrolled = false,
            lastScrollPos = $(window).scrollTop();
        $(window).on('scroll',function (e) {
            hasScrolled = true;
        });

        // Using setInterval to check the hasScrolled variable to determine if the browser has scrolled. If so, close the dropdown for tablet and desktops
        setInterval(function () {
            if (hasScrolled) {
                hasScrolled = false;
                var currentScrollPos = $(window).scrollTop();
                if (Modernizr.mq('(min-width:48em)')) {
                    // ignore small scroll events
                    var scrollDistance = Math.abs(currentScrollPos - lastScrollPos);
                    // hide the dropdowns when the scroll distance is more than 10, this should eliminate the accidental scroll on touch devices (track pads included)
                    if (scrollDistance > 10 && hasNavbar === true) {
                        //navbar.hideAllDropdowns();
                    }
                } else {
                    if (hasHeaderbar === true) {
                        headerSiteSearch.pinState();
                    }
                }
                // update last scroll so the next calculation is based on the current position
                lastScrollPos = currentScrollPos;

                // toggle logo in headerbar based on scroll position
                if (hasHeaderbar === true) {
                    headerbar.pinState(currentScrollPos);
                }
            }
        }, 50);

        // set the headerbar pinState on ready, and then add the listener to recheck pinState when important-updates / koi / browser notice bars are added to the dom
        if (hasHeaderbar === true) {
            headerbar.pinState(lastScrollPos);
            $(document).on('headerbar.pinstate', function () {
                headerbar.pinState(lastScrollPos);
            });
            // end
        }

        // Test to make sure hoverIntent is available before initializing the navbar and my account events
        if (typeof $.fn.hoverIntent == 'function') {
            navbar.init();
            myAccount.init();
            language.check();
        } else {
            loadJS('/js/global/jquery/plugins/hoverIntent/jquery.hoverIntent.js', function () {
                navbar.init();
                myAccount.init();
                language.check();
            });
        }

        /* Show localized content if the localeData exists */
        if (typeof(localeData) == "undefined" || localeData == null) {
            $(document).on('getLocale', function () {
                localize.init();
            });
        } else {
            localize.init();

            headerbar.savedCruises(localeData.country);
        }

        /* Show the Recently Viewed Cruises link in the mobile nav */
        if (docCookies.hasItem('rvc')) {
            $navbar.find('.recently-viewed-cruises').removeClass('hidden');
        }

        /* Enhance skip to content button by scrolling the browser window and focusing on the first element within #content */
        var $skipToContent = $('#skip-to-content');
        $skipToContent.on('click', function () {
            var $content = $('#content');
            if ($content.length > 0) {
                var contentScrollPosition = $content.offset().top;
                if (hasHeaderbar === true) {
                    // adjust scroll position for sticky headerbar
                    contentScrollPosition = contentScrollPosition - 70;
                }
                //scroll browser window
                $('html,body').animate({scrollTop: contentScrollPosition}, 250, function () {
                    // focus on the first visible interactive element in #content
                    $content.find('h1,h2,h3,a,button,input,select,span[role="button"],div[role="button"]').not(':hidden').first().trigger('focus');
                });
            }
        });

        // blur the social links after clicking them
        $('.follow-princess-icon').on('click',function () {
            $(this).trigger("blur");
        });

        // Logic to clear sessionStorage filter in currentBookingSession set from pcl-ube-ui
        try {
            // If user is not currently in cruise-search(polar bear app)
            if (!window.location.pathname.startsWith('/cruise-search')) {
                let country = JSON.parse(window.docCookies.getItem('getLocale')).country;
                if (country) {
                    let sessionKey = `pcl-ube-search-${country}-currentBookingSession`;
                    const data = sessionStorage.getItem(sessionKey);
                    if(data){
                        let bookingSession = JSON.parse(data);
                        delete bookingSession.filters;
                        sessionStorage.setItem(sessionKey, JSON.stringify(bookingSession));
                    }
                }
            }
        } catch(e){
            console.log('error', e)
        }
       
    }); // end docroot ready

    /*
     * Headerbar -
     */
    var headerbar = {
        $this: $('#headerbar'),

        /**
         * fixedScrollPos - using a function because additional items can be inserted/removed in dom after document.ready that would change the
         * @return {[type]} [description]
         */
        fixedScrollPos: function () {
            return headerbar.$this.offset().top;
        },

        pinState: function (currentScrollPos) {
            isHeaderbarPinned = headerbar.$this.hasClass('pinned');
            if (isHeaderbarPinned === true || Modernizr.mq('(max-width:47.9375em)')) {
                var fixedScrollPos = headerbar.fixedScrollPos();
                if (currentScrollPos >= fixedScrollPos) {
                    headerbar.$this.addClass('fixed');
                } else {
                    headerbar.$this.removeClass('fixed');
                }
                /* not in use
                if (isHeaderbarPinned === true) {
                    headerbar.toggleLogo(currentScrollPos, fixedScrollPos);
                }
                */
            }
        },
        toggleLogo: function (currentScrollPos, fixedScrollPos) {
            if (headerbar.$this.hasClass('pinned')) {
                // for fixed header, hide the navbar logo and show the headerbar logo when the headerbar has scrolled past the navbar
                if (currentScrollPos > fixedScrollPos + 40) {
                    headerbar.$this.addClass('fadein-headerbar-logo');
                } else {
                    headerbar.$this.removeClass('fadein-headerbar-logo');
                }
            }
        },
        ssiCleanup: function ($container, environment) {
            $container.find('a').each(function () {
                var $link = $(this),
                    url = $link.attr('href');
                if (url.indexOf('https://') === 0) {
                    var modUrl = url.slice(8);
                    modUrl = 'https://' + environment + modUrl;
                    $link.attr('href',modUrl);
                }
            });
        },
        savedCruises: function (country) {
            // if locale != NAM change href of saved-cruises to CaptainCircle
            var $princessHeader = $('#princess-header');
            if (country === "US" || country === "CA") {
                $princessHeader.find('#saved-cruise-count').closest("a").attr("href", $wwwURL + '/cruise-vacation-planning-dashboard/home.do')
            } else {
                $princessHeader.find('#saved-cruise-count').closest("a").attr("href", $bookURL + '/captaincircle/myPrincess.page')
            }
        }
    };

    /*
     * Localized Elements
     */
    // might need to move these into function that get's called when getLocale is ready
    var localize = {
        init: function () {
            localize.legacy();
            localize.specialOffers();
            localize.phone();
            loclink.init($('#princess-header'));
            loclink.init($('#princess-footer'));
            localeContent.init($('.copyright > .locale-content'), localeData.status);
            privacyNotice.init(localeData.isEU, localeData.country);
        },
        legacy: function () {
            var $footer = jQuery('#footer');
            showPrincessTollFreeNumber($footer);
            displayPrincessRewards($footer);
            displayCruiseDeals($footer);
            displayKoi();
        },
        phone: function () {
            // show the correct phone
            if (typeof localeData.countryPhone == 'string' && localeData.countryPhone.length > 0) {
                var localePhoneNu = localeData.countryPhone;
                $('.loc-phone-nu').text(localePhoneNu);
                $('#headerbar-call-btn,#cp-touch-phone,#navbar-call-btn').prop('href','tel:' + localePhoneNu);
            } else {
                $('#headerbar-call-btn,#cp-touch-phone,#navbar-call-btn,.loc-phone-nu,.navbar-dropdown-phone-nu,.headerbar-btn.call').addClass('hidden');
            }
        },
        specialOffers: function () {
            var $princessHeader = $('#princess-header'),
                $princessFooter = $('#princess-footer');

            if (localeData.specialOffers == 'true') {
                $princessHeader.find('.headerbar-special-offers, .navbar-special-offers').removeClass('hidden');
                $princessFooter.on('click','.special-offers > a', function (e) {
                    e.preventDefault();
                    /** Code for Adobe Analytics ABG-329: start
                     * This is a hack fix for double click tracking on special offer links fired fron the footer.
                     * */
                    if( typeof window.dtm_dataLayer == 'object' )
                    {
                        window.dtm_dataLayer.push({
                            event: 'footer_special_offers_clicked',
                            clickEvent: e,
                            element: this
                        });
                    }
                    /** Code for Adobe Analytics ABG-329: end */
                    $princessHeader.find('.navbar-special-offers')[0].trigger('click');
                });
            }
            else {
                $princessHeader.find('.headerbar-special-offers, .navbar-special-offers').addClass('hidden');
                $princessFooter.find('.special-offers').addClass('hidden');
            }
        }
    };

    /*
     * My Account
     */
    var myAccount = {
        expandDropdown: function ($headerDropdownToggle, eventType) {
            $headerDropdownToggle.attr('aria-expanded','true')
            .closest('.headerbar-btn').addClass('expanded')
            .find('.my-account-dropdown, .language-dropdown').attr('aria-hidden',false)
            .find('.my-account-link, .my-account-logout-btn, .my-account-input, .language-link').attr('tabindex','0');
            // Allow focus on my-account-links by setting tabindex to 0

            // trigger blur on click events
            eventType === 'click' && $headerDropdownToggle.trigger("blur");
        },
        hideDropdown: function ($headerDropdownToggle, eventType) {
            $headerDropdownToggle.attr('aria-expanded','false')
            .closest('.headerbar-btn').removeClass('expanded')
            .find('.my-account-dropdown, .language-dropdown').attr('aria-hidden',true)
            .find('.my-account-link, .my-account-logout-btn, .my-account-input, .language-link').attr('tabindex','-1');
            // Do not allow focus on my-account-links by setting tabindex to -1

            // trigger blur on click events
            eventType === 'click' && $headerDropdownToggle.trigger("blur");
        },
        toggleDropdown: function ($headerDropdownToggle,eventType) {
            var $dropdowns = $('.my-account-dropdown-toggle, .language-dropdown-toggle');
            if ((eventType === 'keydown' || eventType === 'click') && $headerDropdownToggle.attr('aria-expanded') == 'true') {
                myAccount.hideDropdown($headerDropdownToggle, eventType);
            } else {
                myAccount.hideDropdown($dropdowns);
                myAccount.expandDropdown($headerDropdownToggle, eventType);
                navbar.hideAllDropdowns();
            }
        },
        init: function () {
            var $princessHeader = $('#princess-header'),
                $headerDropdownToggle = $princessHeader.find('.my-account-dropdown-toggle, .language-dropdown-toggle'),
                $headerDropdown = $princessHeader.find('.my-account-dropdown, .language-dropdown'),
                $headerCategories = $('.headerbar-btn.my-account, .headerbar-btn.language'),
                $headerDropdownLinks = $headerCategories.find('.my-account-link, .my-account-logout-btn, .my-account-input, .language-link'),
                $headerItem = $princessHeader.find('.my-account, .language'),
                headerMouseleaveTimeout = undefined;

            // Toggle my account dropdown on click with keyboard
            $headerDropdownToggle.on('keydown click', function (e) {
                var isKeyDown = e.which === 13 || e.which === 32,
                    isClick = e.type === 'click';

                if (isKeyDown || isClick) {
                    e.preventDefault();
                    myAccount.toggleDropdown($(this), e.type);
                }
            });

            // Use the esc key to close my-account dropdown
            $(document).on('keydown', function (e) {
                if (e.which === 27 && $headerDropdownToggle.attr('aria-expanded') === 'true') {
                    myAccount.toggleDropdown($headerDropdownToggle,'click');
                }
            });

            // Do not allow focus on my-account-links when not visible
            $headerDropdownLinks.attr('tabindex','-1');

            // prevent default of header bar dropdowns
            $headerDropdownToggle.on('click',function(e) {
                e.preventDefault();
            });

            // hide expanded dropdown when clicking outside the element
            document.querySelector('body').addEventListener('click', function(e) {
                var menu = document.querySelector('.headerbar-menu'),
                    target = e.target,
                    expandedMenu = document.querySelector('.headerbar-btn.expanded');

                // return if click is within dropdown
                if (menu && menu.contains(target) && !target.classList.contains('headerbar-menu')) {
                    return;
                }
                // hide all expanded dropdowns
                if (expandedMenu) {
                    myAccount.hideDropdown($('.headerbar-btn-link'));
                }
            });

            //display links in my-account-dropdown based on user's log in status
            if (!!window.guestAuth && window.guestAuth.status()) {
                myAccount.hasSignedIn($princessHeader, $headerCategories);
            } else {
                myAccount.notSignedIn($princessHeader, $headerCategories);
            }

            // listen for authentication display updates
            window.addEventListener('displayAuth', function (event) {
                !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth: displayAuth in jQuery', event);
                myAccount.hideDropdown($('.headerbar-btn-link')); 
                if (event.detail.status) {
                    myAccount.hasSignedIn($princessHeader, $headerCategories);
                } else {
                    myAccount.notSignedIn($princessHeader, $headerCategories);
                }


                // switch (event.detail.type) {
                //     case 'logoutBackground':
                //     case 'updateWindow':
                //         var refreshDelay = 0;
                //         !!window.digitalDataHelper && !!window.digitalDataHelper.routeLoad(true);
                //         myAccount.pastPaxStatus();
                //         myAccount.hideDropdown($('.headerbar-btn-link'));

                //         if (event.detail.status) {
                //             myAccount.hasSignedIn($princessHeader, $headerCategories);
                //             printNameonGreeting("NAME_ON_TOP_BANNER");
                //         } else {
                //             myAccount.notSignedIn($princessHeader, $headerCategories);
                //             // when updateWindow - we want to display the logout modal
                //             if (event.detail.type === 'updateWindow') {
                //                 guestAuthNotice.logout(event.detail.appType);
                //                 refreshDelay = 5000;
                //             }
                //         }
                //         setTimeout(function () {
                //             jQuery.magnificPopup.close();
                //         }, refreshDelay);
                //         break;
                    
                //     case 'inactive':
                //         guestAuthNotice.inactive();
                //         break;
                    
                //     case 'logoutInprogress':
                //         guestAuthNotice.logoutInprogress(event.detail.appType);
                //         break;
                    
                //     case 'logout':
                //         guestAuthNotice.logout(event.detail.appType);
                //         break;
                    
                //     case 'tokenRefresh':
                //         jQuery.magnificPopup.close();
                //         break;
                // }
            });
        },

        /**
         * hasSignedIn - display required links when user IS signed in
         */
        hasSignedIn: function ($princessHeader, $headerCategories) {
            if ($princessHeader === undefined) {
                var $princessHeader = $('#princess-header');
                var $headerCategories = $princessHeader.find('.headerbar-btn.my-account');
            }

            //legacy function for displaying user's name
            //printNameonGreeting("NAME_ON_TOP_BANNER");

            // bind sign out function to the button
            $princessHeader.find('.my-account-logout-btn').one('click', function () {
                !!window.guestAuth && window.guestAuth.logout(false);
            });

            // show my account text for users who are signed in
            $headerCategories.find('.my-account-dropdown-text').html($headerCategories.find('.my-account-dropdown-text').data('loginText')).closest('.my-account-dropdown-toggle').removeClass('hidden');

            // show links for logged in users (in desktop & mobile menu)
            $princessHeader.find('.user-logged-in').removeClass('hidden').siblings('.user-logged-out').addClass('hidden');

            // show guest name for mobile
            $princessHeader.find('.my-account-welcome-name').removeClass('hidden');

            // hide special offers
            $princessHeader.find('.email-offers').addClass('hidden');

            // show saved cruises when signed in
            $princessHeader.find('.saved-cruises').removeClass('hidden');

            // hide special offers when signed in
            $princessHeader.find('.headerbar-btn.email-offers').addClass('hidden');
        },

        /**
         * notSignedIn - display required links when user NOT signed in
         */
        notSignedIn: function ($princessHeader, $headerCategories) {
            if ($princessHeader === undefined) {
                var $princessHeader = $('#princess-header');
                var $headerCategories = $princessHeader.find('.headerbar-btn.my-account');
            }

            // append src to login link
            var pathname = DOMPurify.sanitize(window.location.pathname),
                appSection = pathname.split('/')[1],
                loginBtn = $princessHeader.find('.my-account-login-btn'),
                loginHref = loginBtn.attr('href'),
                loginModal = ['brochures', 'manage-booking', 'cruise-search', 'find-a-cruise', 'reserve', 'search-cruise'];

            // set the src for login link
            if (loginModal.indexOf(appSection) > -1) {
                $princessHeader.find('.my-account-login-btn').addClass('open-mfp mfp-iframe').attr({
                    'href': loginHref + '?src=' + appSection,
                    'data-mfp-modal': 'false',
                    'data-mfp-main-class': 'mfp-entry-point mfp-entry-point-login',
                    'data-mfp-title': 'Log In to Princess Cruises'
                });
            } else if (appSection !== 'my-princess') {
                $princessHeader.find('.my-account-login-btn').attr('href', loginHref + '?src=' + encodeURIComponent(window.location.href));
            }

            // show sign in / register for users who are not signed in
            $headerCategories.find('.my-account-dropdown-text').html($headerCategories.find('.my-account-dropdown-text').data('logoutText')).closest('.my-account-dropdown-toggle').removeClass('hidden');

            // show links for logged out users (in desktop & mobile menu)
            $princessHeader.find('.user-logged-out').removeClass('hidden').siblings('.user-logged-in').addClass('hidden');

            // hide guest name for mobile
            $princessHeader.find('.my-account-welcome-name').addClass('hidden');

            // display user's name if remember me was cheked at last signin
            // if (hasKeptSignedIn()) {
            //     printNameonGreeting("pnotp");
            // }

            // show special offers
            $princessHeader.find('.email-offers').removeClass('hidden');

            // hide saved cruises when signed out
            $princessHeader.find('.saved-cruises').addClass('hidden');
        },

        /**
         * displayCruiseHistory - retrieve guest status from guestInfo cookie
         */
        pastPaxStatus: function () {
            var checkEccn = window?.guestInfo?.config?.eccn;
            var passengerStatus = window?.digitalData?.user?.profile?.passengerStatus;
            if (!!checkEccn & !!passengerStatus) {
                $(document).on('guestProfile.infoSaved', function() {
                    if (typeof myAccount !== 'undefined') {
                        myAccount.displayCruiseHistory(passengerStatus);
                    }
                });
            }
            /*var guestInfo = guestProfile.getInfo(); 
            if (guestInfo == null) {
                $(document).on('guestProfile.infoSaved', function () {
                    guestInfo = guestProfile.getInfo();
                    myAccount.displayCruiseHistory(guestInfo.guestStatus);
                });
            } else {
                myAccount.displayCruiseHistory(guestInfo.guestStatus);
            } */
        },

        /**
         * [displayCruiseHistory description]
         * @param  {string} guestStatus    - passenger classification
         * @param  {jQuery object} $cruiseHistory - button in my account dropdown
         */
        displayCruiseHistory: function (guestStatus) {
            if (guestStatus === 'Past+Passenger') {
                $('#my-account-cruise-history').removeClass('hidden');
            }
        }
    };

    /*
     * Header Site Search - handles the display mobile vs tablet/desktop views, and handling the form
     */
    var headerSiteSearch = {
        className: function (inMenu) {
            var className = 'visible';
            if (inMenu) {
                className += '-in-menu';
            }
            return  className;
        },
        hide: function (inMenu) {
            // remove class to hide header-site-search
            $('.header-site-search').attr('aria-hidden',true).removeClass(headerSiteSearch.className(inMenu) + ' unpinned');
            headerSiteSearch.dataLayer('hide-site-search',inMenu);
        },
        show: function (inMenu) {
            // add class to show header-site-search
            $('.header-site-search').attr('aria-hidden',false).addClass(headerSiteSearch.className(inMenu));
            headerSiteSearch.dataLayer('view-site-search',inMenu);
            if (!inMenu) {
                $('#header-site-search-field').trigger('focus');
            }
        },
        pinState: function () {
            var windowScrollPos = $(window).scrollTop(),
                $headerSiteSearch = $('.header-site-search'),
                headerSiteSearchHeight = $headerSiteSearch.outerHeight(),
                headerbarHeight = $('.headerbar').outerHeight(),
                $navbar = $('#navbar'),
                menuHeight = ($navbar.length > 0 ? $navbar.data('menuHeight') : 0),
                menuOffsetTop = ($navbar.length > 0 ? $navbar.offset().top : 0);

            if ((windowScrollPos > menuOffsetTop) && (windowScrollPos + headerbarHeight + headerSiteSearchHeight) > (menuHeight + menuOffsetTop)) {
                $headerSiteSearch.addClass('unpinned');
            } else {
                $headerSiteSearch.removeClass('unpinned');
            }
        },
        dataLayer: function (eventType, inMenu) {
            var pageSection;
            if (inMenu) {
                pageSection = 'Navbar';
            } else {
                pageSection = 'Header';
            }
            if (typeof window.dataLayer == 'object') {
                window.dataLayer.push({
                    'event': eventType,
                    'page-section': pageSection
                });
            }
        }
    };

    /*
     * Navbar
     */
    var navbar = {
        expandDropdown: function ($dropdownToggle, eventType) {
            $dropdownToggle.attr('aria-expanded','true').closest('.navbar-dropdown').addClass('expanded').find('.navbar-dropdown-menu').attr('aria-hidden',false).find('a, button').attr('tabindex','0'); // Allow focus on navbar-links by setting tabindex to 0;
            // push down relative content based off drop down height
            $('.navbar').css('margin-bottom', $dropdownToggle.siblings('.navbar-dropdown-menu').height());
            // trigger blur on click events
            eventType === 'click' && $dropdownToggle.trigger("blur");
            /** Code for Adobe Analytics ABG-324: start */
            if( typeof window.dtm_dataLayer == 'object' )
            {
                window.dtm_dataLayer.push({
                      'event': 'primary-nav-roll-over',
                      'element': $dropdownToggle
                  });
            }
            /** Code for Adobe Analytics ABG-324: end */
        },
        hideDropdown: function ($dropdownToggle, eventType) {
            $dropdownToggle.attr('aria-expanded','false').closest('.navbar-dropdown').removeClass('expanded').find('.navbar-dropdown-menu').attr('aria-hidden',true).find('a, button').attr('tabindex','-1'); // Allow focus on navbar-links by setting tabindex to -1;
            // reset relative content
            $('.navbar').css('margin-bottom', 'auto');
            // trigger blur on click events
            eventType === 'click' && $dropdownToggle.trigger("blur");
        },
        hideAllDropdowns: function () {
            var $expandedDropdowns = $('.navbar-dropdown.expanded > .navbar-dropdown-toggle');
            if ($expandedDropdowns.length > 0) {
                navbar.hideDropdown($expandedDropdowns);
            }
        },
        toggleDropdown: function ($dropdownToggle,eventType) {
            if (Modernizr.mq('(min-width: 48em)')) {
                if ( (eventType === 'keydown' || eventType === 'click') && $dropdownToggle.attr('aria-expanded') == 'true') {
                    navbar.hideDropdown($dropdownToggle, eventType);
                } else {
                    navbar.hideAllDropdowns();
                    navbar.expandDropdown($dropdownToggle, eventType);
                    myAccount.hideDropdown($('.my-account-dropdown-toggle'), eventType);
                }
            } else {
                if (eventType == 'keydown' || eventType == 'click') {
                    if ($dropdownToggle.attr('aria-expanded') == 'true') {
                        navbar.hideDropdown($dropdownToggle, eventType);
                    } else {
                        navbar.expandDropdown($dropdownToggle, eventType);
                    }
                    $('#navbar').trigger('mobile-nav-toggle');
                }
            }
        },
        init: function () {
            var $navbar = $('#navbar'),
                $navbarMenu = $('.navbar-menu'),
                $navbarDropdownToggle = $('.navbar-dropdown-toggle'),
                $navbarDropdown = $navbar.find('.navbar-dropdown'),
                $navbarElements = $navbar.find('.my-account-link, .navbar-btn-menu-link, .navbar-dropdown-toggle, .navbar-search-cruises .button, .navbar-search-cruises .recently-viewed-cruises'),
                navbarMouseleaveTimeout = undefined,
                $closeBtn = $('.navbar-close');

            if (!Modernizr.mq('(min-width: 48em)')) {
                $navbarElements.attr('tabindex', '-1');
                $navbar.attr('aria-hidden',true);
            }

            // Toggle navbar dropdown on click or keydown
            $navbarDropdownToggle.on('keydown click', function (e) {
                var isKeyDown = e.which === 13 || e.which === 32,
                    isClick = e.type === 'click';

                if (isKeyDown || isClick) {
                    e.preventDefault();
                    navbar.toggleDropdown($(this), e.type);
                }
            });

            // close btn
            $closeBtn.on('click',function() {
                var $toggle = $(this).closest('.navbar-dropdown').find('.navbar-dropdown-toggle');

                // focus on element
                $toggle.trigger('focus');
                // toggle dropdown
                navbar.hideDropdown($toggle);
            });

            // use the esc key to close my-account dropdown
            $(document).on('keydown', function (e) {
                var $expanded = $navbarDropdownToggle.filter('[aria-expanded="true"]');
                
                if (e.which === 27 && $expanded.length) {
                    // focus on element
                    $expanded.trigger('focus');
                    // toggle dropdown
                    navbar.toggleDropdown($expanded, 'keydown');
                }
            });

            // Do allow focus on navbar links when navbar-dropdown is not visible
            $navbarDropdown.find('.navbar-dropdown-menu').attr('aria-hidden',true).find('a, button').attr('tabindex','-1');

            // Set the height when the mobile nav is toggled
            $navbar.on('mobile-nav-toggle', function () {
                $navbar.data('menu-height',$navbar.height());
            });
        },
        expandMobileMenu: function ($headerbarMenuBtn,$navbar) {
            var navbarHeight = $navbar.find('.navbar-wrapper').height(),
                windowHeight = $(window).height();
            // scroll window to top of page
            $('html,body').stop().scrollTop(0);
            // display header site search
            headerSiteSearch.show(true);
            // animate the opening of the mobile menu
            $navbar.attr('aria-hidden',false).stop().animate({ 'height':navbarHeight}, 400, function(){
                // after animate, set the height to auto so that navbar can expand when a top-level menu item is opened
                $navbar.css('height','auto').trigger('mobile-nav-toggle');
                // need to add a way to remove the style attribute when the device goes from mobile to tablet so that the dropdown will display on tablet/desktop devices after it's been opened (not closed) on mobile... its the overflow: hidden that is causing the issue
            });
            // set the expanded state on the button
            $headerbarMenuBtn.attr('aria-expanded','true').attr('aria-label', 'Menu expanded').addClass('expanded').closest('.headerbar-wrapper').addClass('no-border');
            $navbarElements = $navbar.find('.my-account-link, .navbar-btn-menu-link, .navbar-dropdown-toggle, .navbar-search-cruises .button, .navbar-search-cruises .recently-viewed-cruises');
            $navbarElements.attr('tabindex', '0');
        },
        hideMobileMenu: function ($headerbarMenuBtn,$navbar) {
            // hide header site search
            headerSiteSearch.hide(true);
            // make sure on dropdowns are closed
            navbar.hideAllDropdowns();
            // purge inline styling and trigger the custom event
            $navbar.attr('aria-hidden',true).removeAttr('style').trigger('mobile-nav-toggle');
            // update the expanded state
            $headerbarMenuBtn.attr('aria-expanded','false').attr('aria-label', 'Menu collapsed').removeClass('expanded').closest('.headerbar-wrapper').removeClass('no-border');
            $navbarElements = $navbar.find('.my-account-link, .navbar-btn-menu-link, .navbar-dropdown-toggle, .navbar-search-cruises .button, .navbar-search-cruises .recently-viewed-cruises');
            $navbarElements.attr('tabindex', '-1');
        }
    };

    /**
     * [language]
     * @type {Object}
     */
    var language = {
        /**
         * [check - checks localeData; sends NAM status as arg]
         */
        check: function() {
            if (!localeData) {
                $(document).on('getLocale', function () {
                    language.init(localeData.status);
                });
            } else {
                language.init(localeData.status);
            }
        },
        /**
         * [init - initializes language dropdown functionality]
         * @param  'String' status [NAM identifier]
         */
        init: function(status) {
            var $navBarAnchor = $('.navbar-btn.language .navbar-btn-menu-link'),
                $navBarDropDown = $('.navbar .language-dropdown'),
                $navBarBtn = $('.navbar-btn.language'),
                $container = $('.language');

            // localize anchor urls
            hostDomain.localizeSubDomain($('.language-dropdown'));
            // desktop dropdown anchor links click
            $('.language-link, .navbar-btn.language .navbar-btn-menu-link, .language-arrow').on('click', function(e) {
                language.onClick(e, $(this), $navBarAnchor, $navBarDropDown, $navBarBtn);
            });
            // window resize event
            $(window).on('windowResize', function() {
                language.onResize($navBarDropDown, $navBarBtn);
            });
        },
        /**
         * [onClick - mobile and desktop anchor functionality]
         * @param  {Object} e               [event]
         * @param  {Object} $anchor         [anchor link]
         * @param  {Object} $navBarAnchor   [navbar anchor link]
         * @param  {Object} $navBarDropDown [navbar dropdown content]
         * @param  {Object} $navBarBtn      [navbar language button]
         */
        onClick: function(e, $anchor, $navBarAnchor, $navBarDropDown, $navBarBtn) {
            switch($anchor.data('language-anchor')) {
                // dropdown anchors
                case 'language-link':
                    var languageCode = $anchor.data('language-select').split('|')[0];
                    // set pcl_lang cookie (1 year)
                    if (docCookies.getItem('pcl_lang') !== languageCode) {
                       language.save(languageCode);
                    }
                    // add/remove highlight class
                    $anchor.addClass('active').siblings('.language-link').removeClass('active');
                break;
                // mobile language toggle
                case 'navbar-btn-menu-link':
                    e.preventDefault();
                    if ($navBarBtn.hasClass('expanded')) {
                        $navBarDropDown.slideUp(function() {
                            $anchor.attr('aria-expanded', false);
                            $navBarBtn.removeClass('expanded').find('.navbar-btn-menu-link').trigger('focus');
                        });
                    }
                    else {
                        $navBarDropDown.slideDown(function() {
                            $navBarBtn.addClass('expanded');
                            $anchor.attr('aria-expanded', true);
                        });
                    }
                break;
                // mobile dropdown arrow
                case 'language-arrow':
                    e.preventDefault();
                    $navBarAnchor.trigger('click');
                break;
            }
        },
        /**
         * [save - save pcl_lang cookie]
         * @param  {[type]} languageCode - code for current language
         */
        save: function (languageCode) {
             docCookies.setItem('pcl_lang', languageCode, 31536000, '/', 'princess.com', true);
        },
        /**
         * [onResize - resize event]
         * @param  {Object} $navBarDropDown [navbar dropdown content]
         * @param  {Object} $navBarBtn      [navbar language button]
         */
        onResize: function($navBarDropDown, $navBarBtn) {
            if ($navBarBtn.hasClass('expanded') && Modernizr.mq('(min-width: 48em)')) {
                $navBarDropDown.removeAttr('style');
                $navBarBtn.removeClass('expanded');
            }
        }
    }

    // add ada icon to accessible cruising link in footer
    var $footerADAlink = $('#footer').find('.ada-link > a');
    if ($footerADAlink.length > 0) {
        $footerADAlink.prepend('<span class="pc-icons black-ada-12"></span>');
    }

    var privacyNotice = {
        /* disclaimer markup up */
        auDisclaimer: '<div id="privacy-notice">Our site uses cookies. By continuing to use this site you are agreeing to our <a id="privacy-legal" href="' + $wwwURL + '/legal/legal_privacy/">Privacy Policy</a> and understand we tailor ads to your interests. <button id="close-privacy-notice"><svg class="svg-icon svg-icon-open-close"><use xlink:href="#icon-open-close"></use></svg></button></div>',
        
        /**
         * init - using localeData, check to see if user should see a privacy disclaimer for current locale
         * @param  {string}  countryCode - user's country code
         */
        init: function (countryCode) {
            if (countryCode === 'AU' || countryCode === 'NZ') {
                privacyNotice.check(countryCode);
            }
        },

        /**
         * cookieName -  cookie name for current environment
         * @type {string}
         */
        cookieName: function () {
            return docCookies.nameItem('pcl_pn');
        },

        /**
         * check - checks if user has accept privacy policy agreement
         * @param  {string} region - user's country code
         */
        check: function (region) {
            var privacyStatus = privacyNotice.getStatus();

            console.log('check privacyStatus', privacyStatus);

            if (privacyStatus === null || privacyStatus[region] === undefined) {
                privacyNotice.display(region, privacyStatus);
            } else {

                if (privacyStatus[region] === undefined) {
                    // if user has not accepted privacy for current locale, display privacy notice
                    privacyNotice.display(region, privacyStatus);
                } else {
                    // if we need to user to re-accept privacy notice, put timestamp evaluation here
                }
            }
        },

        /**
         * display - inserts the privacy notice into the DOM and attaches the click event for acknowledging
         * @param  {string} region - user's country code
         */
        display: function (region, privacyStatus) {
            var disclaimer;

            if (region === 'EU') {
                disclaimer = privacyNotice.euDisclaimer;
            } else {
                disclaimer = privacyNotice.auDisclaimer;
            }

            // display notice
            $('body').append(disclaimer);

            var $privacyNotice = $('#privacy-notice');

            $privacyNotice.animate({ 'bottom':'15px'}, 800);

            // acknowledge event
            $privacyNotice.on('click', 'a, button', function (e) {
                e.preventDefault();

                var $element = $(e.target);

                privacyNotice.saveStatus(region, privacyStatus);

                $privacyNotice.stop().animate({'bottom':'-500px'}, 800, function() {
                    if($element.is('a')){
                        window.location = DOMPurify.sanitize($element.prop('href'));
                    }
                });
            });
        },

        /**
         * getStatus - get data from privacy cookie
         * @return {object} - privacy acceptance for one or more region
         */
        getStatus: function () {
            //Retrieve the object form storage
            var privacyCookie = docCookies.getItem(privacyNotice.cookieName());

            if (privacyCookie) {
                return JSON.parse(DOMPurify.sanitize(privacyCookie));
            } else {
                return null;
            }
        },

        /**
         * saveStatus - save acceptance of privacy for current locale
         * @param  {string} region - user's current locale
         * @param  {object / null} privacyStatus - data on previous privacy acceptance
         */
        saveStatus: function (region, privacyStatus) {
            var privacyCookieName = privacyNotice.cookieName();

            // reset to an object if null
            if (privacyStatus === null) {
                privacyStatus = {}
            }

            // add current region with timestamp of acceptance
            privacyStatus[region] = privacyNotice.timestamp.getTime();

            // save privacyStatus cookie
            docCookies.setItem(privacyCookieName, JSON.stringify(privacyStatus), privacyNotice.expireTimestamp(), '/', null, false); // need to swap the null for 'princess.com' when in dev/stage/prod
        },

        /**
         * [timestamp] - new date object based on current time
         * @type {Date}
         */
        timestamp: new Date(),

        /**
         * [expireDatestamp] - sets the expiration date for cookies.
         * @return {[date object]} - 1 year from timestamp
         */
        expireTimestamp: function () {
            var expire = new Date(privacyNotice.timestamp.getFullYear()+5, privacyNotice.timestamp.getMonth(), privacyNotice.timestamp.getDate(), privacyNotice.timestamp.getHours(), privacyNotice.timestamp.getMinutes());
            return expire;
        }
    }

    /*
    var guestAuthNotice = {
        inactive: function (appType) {
            var content = {
                event: 'inactive',
                title: '<svg aria-hidden="true" class="svg-icon svg-icon-warning"><use xlink:href="#icon-warning"></use></svg> Taking a break?',
                description: 'Due to inactivity, your browsing session is about to expire. To maintain security of your information, you will automatically be signed out.',
                primaryCTA: 'Continue my session',
                secondaryCTA: 'Log me out',
                appType: appType
            }
            this.renderNotice(content);
        },
        logoutInprogress: function (appType) {
            var content = {
                event: 'logoutInprogress',
                title: 'Logging out&hellip;',
                description: 'Please wait while we log you out.',
                appType: appType
            }
            this.renderNotice(content);
        },
        logout: function (appType) {
            var content = {
                event: 'logoutComplete',
                title: 'You have been logged out',
                description: 'Your window will update in 5 seconds.',
                secondaryCTA: 'Close window',
                appType: appType
            }
            this.renderNotice(content);
        },
        renderNotice: function (content) {
            !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth: notice', content);

            var template = this.template(content);

            $.magnificPopup.open({
                items: {
                    src: template,
                    type: 'inline'
                },
                removalDelay: 300,
                alignTop: false,
                modal: true,
                mainClass: 'border-box-sizing mfp-guestauth-notice',
                overflowY: 'scroll',
                callbacks: {
                    open: function () {
                        var item = this.currItem,
                            container = this.container;

                        container.on('click', '#guestauth-notice-primary-cta', function () {
                            var event = $(this).data('guestauthNotice');
                            switch (event) {
                                case 'inactive':
                                    container.find('.guestauth-notice-cta').addClass('hidden');
                                    container.find('.guestauth-notice-processing').removeClass('hidden');
                                    !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - primary cta refreshToken');
                                    !!window.guestAuth && window.guestAuth.tokenRefresh();
                                    break;
                            }
                        });

                        container.on('click', '#guestauth-notice-secondary-cta', function () {
                            var event = $(this).data('guestauthNotice');
                            var appType = $(this).data('guestauthAppType');
                            switch (event) {
                                case 'inactive':
                                    container.find('.guestauth-notice-cta').addClass('hidden');
                                    container.find('.guestauth-notice-processing').removeClass('hidden');
                                    !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - secondary cta logout');
                                    !!window.guestAuth && window.guestAuth.logout();
                                    break;
                                case 'logoutComplete':
                                    !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - secondary cta logoutComplete / refresh window');
                                    if (appType === 'spa') {
                                        $.magnificPopup.close();
                                    } else {
                                        window.location.reload();
                                    }
                                    break;
                            }
                        });
                    }
                }
            });
        },
        template: function (content) {
            var displayPrimaryCTA = !content.primaryCTA ? 'hidden' : '';
            var displaySecondaryCTA = !content.secondaryCTA ? 'hidden' : '';
            return `<div id="popupContainer" class="clearfix popupContainer tpl-v4 guestauth-notice"><header class="article-header"><div class="article-title"><div class="container"><h3 class="align-center font-size-p10">${content.title}</h3></div></div></header><div class="article-container clearfix white-bg col-xs-pad-15-bottom col-xs-pad-15"><div class="container col-xs-pad-15-bottom align-center"><p>${content.description}</p><div class="col-xs-pad-15-top guestauth-notice-cta ${displayPrimaryCTA}"><button type="button" class="button green-btn font-size-p5" id="guestauth-notice-primary-cta" data-guestauth-notice="${content.event}" data-guestauth-app-type="${content.appType}">${content.primaryCTA}</button></div><div class="col-xs-pad-10-top guestauth-notice-cta ${displaySecondaryCTA}"><button type="button" class="plain-text-btn" id="guestauth-notice-secondary-cta" data-guestauth-notice="${content.event}" data-guestauth-app-type="${content.appType}">${content.secondaryCTA}</button></div><div class="col-xs-pad-15-top guestauth-notice-processing hidden">Processing&hellip;</div></div></div></div>`
        }
    }*/
}(jQuery));

jQuery(document).ready(function (){
    /* To track the links for Google Analytics */
    var pagename="";
    if (jQuery("#princess-header").attr('data-pagename') !== undefined) {
        pagename = jQuery("#princess-header").attr('data-pagename');
    }

    /*Run P13N in footer*/
    runP13N('#princess-footer');

    /* KOI Site Notice */
    jQuery(document).on('click','.lightbox-close-window-top > .close-btn, .koi-link',function(e){
        e.preventDefault();
        var target = jQuery(e.target);

        if(jQuery('#koi-mini-site').length > 0 ){
            docCookies.setItem('pcl-mn-koi-site', 'true', 'undefined', '/', '.princess.com', true);
        }
        if(target.hasClass('koi-link')) {
            window.setTimeout(function(){
                window.location = target.attr('href');
            },200);
        }
    });

    /* Prevent click on top level anchor tags in menu if using touch browser with gesture support */
    if (Modernizr.hasEvent('touchend') && $(window).width() < 1025) {
        jQuery('.sf-with-ul > a').on('click', function(e) {
             e.preventDefault();
        });
    }
}); /*end document.ready*/

//for encoding search terms for use in url
function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

/* Delaying the equalFooterCol until after the page loads to ensure everything is in the footer before calculating the min-height for desktop.
 * This is being done to resolve an issue in Chrome and IE where the row height was being incorrectly calculated and setting the min-height to an extremely large value.
 */
jQuery(window).on('load', function () {
    if (!jQuery('html').hasClass('oldie')) {
        equalFooterCol();

        jQuery(window).on('resize', function () {
            equalFooterCol();
        });
    }

});

/* Equalize footer columns */
function equalFooterCol() {
    jQuery('.footerCol').css('min-height', 0);

    if (jQuery(window).width() >= 960) {
        jQuery('.footerCol').each(function() {
            var target = jQuery(this),
                rowHeight = target.closest('.row').height();
            if (target.outerHeight() < rowHeight) {
                target.css('min-height', rowHeight);
            }
        });
    } else {
        var target = jQuery('.footerCol.get-brochure'),
            rowHeight = target.closest('.row').height();
        if (target.outerHeight() < rowHeight) {
            target.css('min-height', rowHeight);
        }
    }
}

/*
 * Show Toll Free Numbers in header & footer
 */
function getPhoneNumber() {
    if (typeof localeData.countryPhone == 'string') {
        return localeData.countryPhone;
    } else {
        return '';
    }
}

function showPrincessTollFreeNumber(){
    var countries = ['UK', 'GB', 'AU', 'NZ', 'US', 'CA', 'MX', 'SG', 'VN', 'BN', 'MY', 'ID', 'HK', 'PR', 'AR', 'PE', 'CL', 'TW', 'CO', 'PA', 'CR', 'SV', 'DO', 'CN', 'BO', 'KP', 'KR'];
    if (countries.indexOf(localeData.country) > -1) {
        jQuery('.toll-free-number').text(getPhoneNumber());
        jQuery('#footer-contact').append('<a class="invert-decoration" href="tel:' + getPhoneNumber() + '">Call: ' + getPhoneNumber() + '</a>');
        if (jQuery('.toll-free-placeholder').is(":hidden")) {
            jQuery('.toll-free-placeholder').show();
        }
    } else {
        jQuery('.toll-free-placeholder').html('');
    }
}

//Function returns the value of the QueryString
function getQueryVariable(variable) {
   var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return null;
}

function displayPrincessRewards($footer) {
    if (loc != 'US' && $footer.length > 0) {
        $footer.find('.sublinks_princess-rewards').hide().prev('h2').hide();
    }
}

function displayCruiseDeals($footer) {
    if (localeData.brochures == 'false') {
        if ($footer.length > 0) {
            $footer.find('.sublinks_cruise-deals').hide().prev('h2').hide();
        }
    }
}

function displayKoi() {
    const { country } = localeData;
    var popupContent = '/html/global/popups/koi/' + country + '.html';
    var koiBarLink, koiBarLinkText, koiShowPop, koiBar, koiHideBar;
    let koiBarFormat = (link, linkText) => `<div id="koi-alert"><div><a href="${link}">${linkText}</a></div></div>`
    switch(country){
        case 'CN':
            koiBarLink = 'http://www.princesschina.com/';
            koiBarLinkText = '&#35775;&#38382;&#20013;&#25991;&#23448;&#32593;&#65292;&#35831;&#28857;&#20987;&#27492;&#22788; http://www.princesschina.com/';
            koiBar = koiBarFormat(koiBarLink, koiBarLinkText);
            koiShowPop = false;
            koiHideBar = true;
            break;
        case 'HK':
            koiShowPop = false;
            koiHideBar = true;
            break;
        case 'KR':
            koiBarLink = 'http://www.princesscruises.co.kr/';
            koiBarLinkText = '&#54532;&#47536;&#49464;&#49828;&#53356;&#47336;&#51592; &#54620;&#44397; &#50937;&#49324;&#51060;&#53944;&#47484; &#48169;&#47928; &#54616;&#47140;&#47732; &#51060;&#44275;&#51012; &#53364;&#47533;&#54616;&#49464;&#50836;. http://www.princesscruises.co.kr/';
            koiBar = koiBarFormat(koiBarLink, koiBarLinkText);
            koiShowPop = true;
            break;
        case 'JP':
            koiBarLink = 'http://www.princesscruises.jp/';
            koiBarLinkText = '&#12503;&#12522;&#12531;&#12475;&#12473;&#12539;&#12463;&#12523;&#12540;&#12474;&#26085;&#26412;&#35486;&#23554;&#29992;&#12469;&#12452;&#12488;&#12399;&#12371;&#12385;&#12425; http://www.princesscruises.jp/';
            koiBar = koiBarFormat(koiBarLink, koiBarLinkText);
            koiShowPop = true;
            break;
    }
    !koiHideBar && jQuery('#container').prepend(koiBar).trigger('headerbar.pinstate');

    // display popup
    if(docCookies.getItem('pcl-mn-koi-site') !== 'true' && koiShowPop){
        docCookies.setItem('pcl-mn-koi-site', 'true', 'undefined', '/', '.princess.com', true);
        openLightbox(popupContent,false,true,'no-min-width','');
        sessionStorage.setItem('pcl-mn-koi-site', 'true');
        // TODO: Remove openLightbox as part of AEM
        // $.magnificPopup.open({
        //     closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button title="%title%" class="mfp-close icon-no-text" data-test-id="mfp-close"></button></div></div>',
        //     items: { src: popupContent },
        //     type: 'ajax',
        //     modal: false,
        //     removalDelay: 300,
        //     mainClass: 'localize-site-lightbox border-box-sizing',
        //     overflowY: 'scroll',
        //     callbacks: {
        //         open: function () {
        //             sessionStorage.setItem('pcl-mn-koi-site', true);
        //         },
        //     }
        // });
    }
}

jQuery.isBlank = function(object) {
    return (
        (jQuery.isPlainObject(object) && jQuery.isEmptyObject(object)) ||
        (Array.isArray(object) && object.length === 0) ||
        (typeof object === "string" && object.trim() === "") ||
        (!object)
    );
};

function runP13N(selector){
    if(typeof P13NListItem !== 'undefined') {

        var p13n_list_item;

        P13NListItem.prototype.url = $p13nURL + "/soln-p13n/delivery/deliver";

        if(typeof selector == 'undefined' || selector == ''){
            selector = document;
        }

        jQuery(selector).find("span[class^='p13nListItemId']").each(function() {
            var temp_id_holder = (this.className.split('_'))[1];
            if(P13NListItem) {
                p13n_list_item = new P13NListItem(temp_id_holder, '', '', '', '');
                p13n_list_item.run();
            }
        });

    }
}
