/**
 * pclViolatorBar - displays a bar with call to action and count down timer at the top of the web page
 * @type {Object}
 */
var pclViolatorBar = {
/**
 * buildPromoObj - this function is called from Target for stand alone violator bars or CMS driven site wide violator bar or one off CMS pages violator bar
 * @param {*} jsonData - this holds all the details about the violator bar
 * @param {*} audienceArr - audience information coming from different sources, it could be country, state, dmz - sky is the limit
 */
    buildPromoObj: function(jsonData, audienceArr) {
        jsonData && jsonData.data.forEach(function(violatorBar) {
            var enableDebug =  violatorBar.debug;
            /** 
             * isSupportedLocale - if the user matches the locale set - currently its only countries but can be extended to other personalization options in future 
             * isUrlExcluded - if url is an excluded url return and dont show violator bar
             * isFutureBookedAccepted - are we allowing future booked users to see the violator bar

            */
            var isSupportedAudience = pclViolatorBar.voilatorBarAudienceMatch(audienceArr, violatorBar.audience_value, enableDebug);
            var isUrlExcluded = pclViolatorBar.excludedURL(violatorBar.excluded_urls, enableDebug);
            var isFutureBookedAccepted = pclViolatorBar.checkForFutureBooked(violatorBar.show_future_booked, enableDebug);

                if(isSupportedAudience && !isUrlExcluded && isFutureBookedAccepted){
                    var promoObj, promoStartDate, promoEndDate;
                    var promoObj, promoStartDate, promoEndDate;
					var currentDate = new Date();
					promoStartDate = violatorBar.sale_start_date + " " + timezone.getPacificTimezone(new Date(violatorBar.sale_start_date));
					promoStartDate = new Date(promoStartDate);
					promoEndDate = violatorBar.sale_end_date + " " + timezone.getPacificTimezone(new Date(violatorBar.sale_end_date));
                    promoEndDate = new Date(promoEndDate);
                    
                if (enableDebug) {
                    console.log("promoStartDate = ",promoStartDate," currentDate = ",currentDate, "promoEndDate = ", promoEndDate);
                }    
                /**
                 * 
                 * isThisPreview - if there are preview parameters present OR if the current date is greater than promotion start date show the violator bar 
                 */
                var isThisPreview = pclViolatorBar.previewViolatorBar(violatorBar.promotion_id);
                if( isThisPreview || (promoStartDate <= currentDate && promoEndDate >= currentDate)){
                    promoObj = {
                        ctaText: violatorBar.cta_txt,//'All Aboard Sale. Save up to 50% off pp~ - Hurry, ends in',
                        ctaEndText: violatorBar.cta_end_txt,//'',
                        ctaBtnText: violatorBar.cta_btn_txt,//'Book Now!',
                        promotionID: violatorBar.promotion_id,//'au-all-aboard-sale-11-15-2019',
                        debug: enableDebug,
                        showCountdown: violatorBar.show_count_down,
                        locale: violatorBar.country_locale,//'US',
                        saleEndDate: promoEndDate,//'Jan 21, 2020 04:59:59 PDT',
                        url: eval(violatorBar.url_type) + violatorBar.url,
                        futureBooked: violatorBar.show_future_booked
                    }
                    if(promoObj.showCountdown){
                        promoObj.template = '<div role="region" aria-label="'+ promoObj.ctaText +'" class="info-bar-wrapper lto-promo hidden" style="background-color:' + violatorBar.bg_color + ';"><div class="container"><a href="' + promoObj.url + '" class="info-bar" data-promotion-id="' + promoObj.promotionID + '"><span class="lto-cta"> ' + promoObj.ctaText + '</span> <span class="lto-end no-wrap"></span> <span class="underline no-wrap">'+ promoObj.ctaBtnText +'</span></a></div></div>';
                    } else {
                        promoObj.template = '<div role="region" aria-label="'+ promoObj.ctaText +'" class="info-bar-wrapper lto-promo" style="background-color:' + violatorBar.bg_color + ';"><div class="container"><a href="' + promoObj.url + '" class="info-bar" data-promotion-id="' + promoObj.promotionID + '"><span class="lto-cta"> ' + promoObj.ctaText + '</span> <span class="underline no-wrap">'+ promoObj.ctaBtnText +'</span></a></div></div>';
                    }
                pclViolatorBar.loadBar(promoObj);
                }
                }
        });

    },

    /**
     * localeCheck - initialize the bar from standalone CMS page, this function will be called.
     * @param  {object} promo - settings for current violator bar
     */
    localeCheck: function (promo) {
        guestLocale && guestLocale.isReady(function() {
            var audienceArr = [localeData.status];
            pclViolatorBar.buildPromoObj(promo, audienceArr);
        });
    }, 
    /**
     * load - add the violator bar to the dom, below the header area for none future booked guests. Then initialize the count down timer
     * @param  {object} promo - settings for current violator bar
     */
    loadBar: function (promo) {
        $(promo.template).insertAfter('pcl-global-nav').next('.breadcrumb-nav').hide();
            // select violator bar and end time to make updates
            var $ltoPromoBar = $('.info-bar-wrapper.lto-promo'),
                $ltoEnd = $ltoPromoBar.find('.lto-end');
                    if(promo.showCountdown){
                        var $ltoEnd = $ltoPromoBar.find('.lto-end');    
                        // calculate and display count down time
                        pclViolatorBar.updateCountDown(promo, $ltoPromoBar, $ltoEnd);
                        // check count down time every minute
                        pclViolatorBar.countDownTimer = setInterval(pclViolatorBar.updateCountDown, 60000, promo, $ltoPromoBar, $ltoEnd);
                        // add mbox click tracking, 
                        }
                    $ltoPromoBar.find('a').on('click',function () {
                        adobe.target.trackEvent({mbox: promo.promotionID});
                    });
        
    },
    
    /**
     * updateCountDown - controls the display of the violator bar and updates the count down
     * @param  {object} promo - settings for current violator bar
     * @param  {jQuery Obj} $ltoPromoBar - the entire violoator bar in the page <div class=".info-bar-wrapper.lto-promo">
     * @param  {jQuery Obj} $ltoEnd - the span that holds the ends in text <span class="lto-end"> 
     */
    updateCountDown: function (promo,$ltoPromoBar,$ltoEnd) {
        var countDown = pclViolatorBar.countDown(promo);
            if (promo.debug) {
                console.log('countDown:', countDown);
            }
            if (countDown.status === true) {
                // count down timer will show days until the last day, we don't need to update the dom if its the same value
                if ($ltoEnd.text() !== countDown.text) {
                        $ltoEnd.text(countDown.text);
                        // show lto-promo bar if not visible
                        if ($ltoPromoBar.hasClass('hidden')) {
                            $ltoPromoBar.removeClass('hidden');
                        }
                }
                if (promo.debug) {
                    console.log('pclViolatorBar: sale active');
                }
            } else {
                // false means the sale is over, hide the bar and stop the timer
                $ltoPromoBar.addClass('hidden');
                $ltoEnd.text('');
                    if (promo.debug) {
                        console.log('pclViolatorBar: sale over');
                    }
                clearInterval(pclViolatorBar.countDownTimer);
            }
    },
    /**
     * countDown - calculates the count down to when the sale ends. If greater than 24 hours, day count is returned, otherwise its in hours and minutes. When after sale, false is returned.
     * @param  {object} promo - settings for current violator bar
     * @return {object} - 
     */
    countDown: function (promo) {
        if(promo.debug){
            console.log("sale end date in countdown",promo.saleEndDate);
        }
        var saleEnd = new Date(promo.saleEndDate),
            saleEndTimestamp = saleEnd.getTime(),
            currentDate = new Date(),
            currentTimestamp = currentDate.getTime(),
            countDownObj = {};
        
        if(promo.debug){
            console.log("sale end date in countdown GET TIME()",saleEndTimestamp);
        }

        // make sure current timestamp is not past sale end timestamp
        if (saleEndTimestamp > currentTimestamp) {
            var timeRemaining = saleEndTimestamp - currentTimestamp,
                day = 86400000,
                hour = 3600000,
                minute = 60000,
                daysRemaining = Math.floor(timeRemaining/day),
                hoursRemaining = 0,
                minutesRemaining = 0;

            // sale is active
            countDownObj.status = true;
            countDownObj.text = promo.ctaEndText;

            if (daysRemaining > 0) {
                // if more than one day, include an s at the end of Days
                countDownObj.text += daysRemaining + ' ' + ((daysRemaining === 1) ? 'day' : 'days') + '.';
            } else {
                hoursRemaining = Math.floor(timeRemaining/hour);
                timeRemaining=timeRemaining%hour;
                minutesRemaining=Math.floor(timeRemaining/minute);
                    //display hours
                    if (hoursRemaining > 0) {
                        countDownObj.text += hoursRemaining + 'h';
                        countDownObj.text += ' : ';
                    }
                //display minutes
                countDownObj.text += minutesRemaining + 'm' + '.';
                    // if there is no time left, display now
                    if (hoursRemaining === 0 && minutesRemaining === 0) {
                        countDownObj.text = 'ends now.';
                    }
            }
        } else {
        // sale is over
            countDownObj.status = false;
        }
    // return countDownObj to update violator bar
    return countDownObj;
    },

    /**
     * to fetch query parameters from the url.
     * @param {*} parameterName -  name of the parameter to find in the query string.
     */
    findGetParameter: function (parameterName) {
        var items = location.search.substr(1).split("&"),tmp,result;
            items.forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;

    },
    /**
     * checkExcludeURL - returns true if excluded url
     * @param excludeUrls - the urls to be excluded
     */
    previewViolatorBar: function (promotion_id) {
        if ((pclViolatorBar.findGetParameter( "preview" ) === "violatorBar") 
            && (pclViolatorBar.findGetParameter( "promo_id" ) === promotion_id)) {
            return true;
        }
        else{
            return false;
        }
    },
    /**
     * checkExcludeURL - returns true if excluded url
     * @param excludeUrls - the urls to be excluded
     */
    excludedURL: function (excludeUrls, enableDebug) {
        if(excludeUrls === ''){
            return false;
        }
        var windowlocation = window.location.pathname;
        var excludeUrlsArr = excludeUrls.split(",");
            if (enableDebug) {
                console.log("excludeUrlsArr",excludeUrlsArr);
            }
        return excludeUrlsArr.some(function (exUrlVal){
        return exUrlVal === windowlocation
        })
    },

    /**
     * audienceMatch - checks if the current user is the desired audience, currently only catering to locale(country)
     * @param  {boolean} enableDebug - boolean checking if debugging is on
     * @param  {string} audienceValue - audience type 
     */
    voilatorBarAudienceMatch: function (audienceArr, audienceValue, enableDebug) {
        return audienceValue === 'GLOBAL' || audienceArr.some(function(audience) {
            return audience === audienceValue
        }); 
    },
    /**
     * checkForFutureBooked - checks if the current user has future booking and is allowed to see this violator bar)
     * @param  {boolean} enableDebug - boolean checking if debugging is on
     * @param  {string} futureBooked - flag value from violatar bar object 
     */
    checkForFutureBooked: function (futureBookFlag, enableDebug) {
        var isFutureBookedAllowed = true;
            if (typeof guestProfile === 'object') {
                var guestInfo = guestProfile.getInfo();
                    if(guestInfo && guestInfo.futureBooked === 'Y' && futureBookFlag){
                        isFutureBookedAllowed = false;
                    }
                }
            return isFutureBookedAllowed;
            }
    }